













import { defineComponent } from "@/composition-api";
import ArrowNav from "../../assets/vectors/icons/arrow-nav-right.svg";

export default defineComponent({
  components: {
    ArrowNav
  },
  props: {
    left: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
});
