import { render, staticRenderFns } from "./User.vue?vue&type=template&id=8b869d60&scoped=true&"
import script from "./User.vue?vue&type=script&lang=ts&"
export * from "./User.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("@/styles/font-weight.module.css?vue&type=style&index=0&module=%24fw&lang=css&")
if (style0.__inject__) style0.__inject__(context)
this["$fw"] = (style0.locals || style0)
var style1 = require("@/styles/font.module.css?vue&type=style&index=1&id=8b869d60&scoped=true&lang=css&")
if (style1.__inject__) style1.__inject__(context)
var style2 = require("./User.vue?vue&type=style&index=2&id=8b869d60&scoped=true&lang=css&")
if (style2.__inject__) style2.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "8b869d60",
  null
  ,true
)

export default component.exports