import { TrainingHttp } from "@/http/client/training.http";
import { TrainingInterface, TrainingState, VideoPlayer } from "@/interfaces";

const state: TrainingState = {
  trainingPages: [],
  trainings: [],
  visitedTrainingIds: []
};

const actions = {
  fetchLearningTopicTrainingVideos: async ({ commit }, learningTopicId) => {
    const filters = [
      { path: "field_learning_topics.id", value: learningTopicId }
    ];
    const trainings = await TrainingHttp.getTrainingVideos(filters);
    commit("addTrainings", trainings);
  },

  sendVisitedTrainingStatus: async ({ state }, trainingId) => {
    if (state.visitedTrainingIds.includes(trainingId)) return;
    await TrainingHttp.postVisitedStatus(trainingId);
    state.visitedTrainingIds.push(trainingId);
  },

  getVisistedTrainingStatus: async ({ commit }) => {
    const trainingIds = await TrainingHttp.getVisitedStatus();

    commit("setVisitedTrainingIds", trainingIds);
  }
};

const getters = {
  currentVideo: state => (trainingId): VideoPlayer | undefined => {
    const training = state.trainings.find(({ id }) => id === trainingId);
    return training?.video;
  }
};

const mutations = {
  addTrainings(state, trainings: TrainingInterface[]) {
    state.trainings = trainings;
  },

  setVisitedTrainingIds(state, trainingIds: string[]) {
    state.visitedTrainingIds = trainingIds;
  }
};

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
};
