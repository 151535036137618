


















import Vue from "vue";
import UiCard from "@/components/ui/UiCard.vue";

export default Vue.extend({
  components: {
    UiCard
  }
});
