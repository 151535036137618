import { ApiEndpoint } from "../endpoint";
import { PracticalExamplesHubResponseTranslator } from "../translators/practical-examples";
import { DrupalJsonApiParams } from "drupal-jsonapi-params";
import { PracticalExample, PracticalExamplesHub } from "@/interfaces";
import useApi from "@/composition/useApi";
import { TrainingResponseTranslator } from "../translators/training";
import { PracticalExamplesResponseTranslator } from "../translators/practical-examples";
import { sortByChangedDate } from "../translators/utils";

export class PracticalExamplesHttp {
  static async getPracticalExamplesHub(): Promise<PracticalExamplesHub> {
    const { client } = useApi();
    const apiParams = new DrupalJsonApiParams();
    apiParams.addInclude(["field_training_lists.field_filter_tags"]);
    const queryString = apiParams.getQueryString({ encode: false });

    const data = await client.get(
      `${ApiEndpoint.PracticalExamplesHub}?${queryString}`
    );

    const practicalExamplesHub = PracticalExamplesHubResponseTranslator(data);
    return practicalExamplesHub;
  }

  static async getPracticalExamples(
    filters = [],
    sort = null
  ): Promise<PracticalExample[]> {
    const { client } = useApi();
    const videoApiParams = new DrupalJsonApiParams();
    videoApiParams.addInclude([
      "field_chapters",
      "field_thumbnail",
      "field_tags",
      "training_type",
      "field_learning_topics"
    ]);
    if (filters.length) {
      filters.forEach(filter => {
        videoApiParams.addFilter(filter.path, filter.value, filter.operator);
      });
    }
    if (sort) {
      videoApiParams.addSort(sort.path, sort.direction || "DESC");
    }
    const videoQueryString = videoApiParams.getQueryString({ encode: false });
    const videos = await client.get(
      `${ApiEndpoint.TrainingVideo}?${videoQueryString}`
    );
    const videosContent = TrainingResponseTranslator(videos);

    const interactiveApiParams = new DrupalJsonApiParams();
    interactiveApiParams.addInclude([
      "field_thumbnail",
      "field_tags",
      "training_type",
      "field_learning_topics"
    ]);
    if (filters.length) {
      filters.forEach(filter => {
        interactiveApiParams.addFilter(
          filter.path,
          filter.value,
          filter.operator
        );
      });
    }
    if (sort) {
      interactiveApiParams.addSort(sort.path, sort.direction || "DESC");
    }
    const interactiveQueryString = interactiveApiParams.getQueryString({
      encode: false
    });
    const interactive = await client.get(
      `${ApiEndpoint.InteractiveContent}?${interactiveQueryString}`
    );
    const interactiveContent = PracticalExamplesResponseTranslator(interactive);
    return [videosContent, interactiveContent].flat().sort(sortByChangedDate);
  }
}
