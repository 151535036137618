<template functional>
  <span
    class="inline-block px-2 text-xs font-semibold leading-snug tracking-widest uppercase whitespace-no-wrap rounded-full"
    :class="[
      data.staticClass,
      {
        'bg-green':
          (data.staticClass && !data.staticClass.includes('bg-')) ||
          (data.class && !data.class.includes('bg-')),
        'text-white':
          (data.staticClass && !data.staticClass.includes('text-')) ||
          (data.class && !data.class.includes('text-'))
      },
      data.class
    ]"
  >
    <slot></slot>
  </span>
</template>

<script>
export default {};
</script>
