




















import { defineComponent, computed } from "@vue/composition-api";
import LearningTopicHeader from "../components/FcLearningTopicHeader/FcLearningTopicHeader.vue";
import FcRichText from "@/components/FcRichText/FcRichText.vue";
import SummaryList from "@/components/FcSummaryList/FcSummaryList.vue";
import { summaryPage } from "@/store/utils";

export default defineComponent({
  components: {
    LearningTopicHeader,
    FcRichText,
    SummaryList
  },
  setup(props, { root }) {
    root.$store.dispatch("tool/fetchSummaryList");

    const title = summaryPage.title;
    const headerIllustrationUrl = "/images/summary-page-header.svg";
    const description = summaryPage.description;
    const primaryColor = "green";
    const summaryList = computed(() => root.$store.state.tool.summaryList);

    return {
      title,
      headerIllustrationUrl,
      primaryColor,
      description,
      summaryList
    };
  }
});
