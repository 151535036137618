









































import Vue, { PropType } from "vue";
import { TypeTagInterface } from "@/interfaces";
import FcRichText from "@/components/FcRichText/FcRichText.vue";
import FcTag from "@/components/FcTag/FcTag.vue";
export default Vue.extend({
  components: {
    FcRichText,
    FcTag
  },
  props: {
    typeTag: {
      type: Object as PropType<TypeTagInterface>,
      required: true
    },
    application: {
      type: String,
      required: true
    },
    knowledge: {
      type: String,
      required: true
    },
    usefulness: {
      type: String,
      required: true
    }
  }
});
