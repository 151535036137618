import { computed } from "@vue/composition-api";
import store from "@/store";

export default () => {
  const drupalJWT = computed(() => {
    return store.state.auth.drupalJWT;
  });

  return { drupalJWT };
};
