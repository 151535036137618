var render = function (_h,_vm) {var _c=_vm._c;return _c('span',{staticClass:"inline-block px-2 text-xs font-semibold leading-snug tracking-widest uppercase whitespace-no-wrap rounded-full",class:[
    _vm.data.staticClass,
    {
      'bg-green':
        (_vm.data.staticClass && !_vm.data.staticClass.includes('bg-')) ||
        (_vm.data.class && !_vm.data.class.includes('bg-')),
      'text-white':
        (_vm.data.staticClass && !_vm.data.staticClass.includes('text-')) ||
        (_vm.data.class && !_vm.data.class.includes('text-'))
    },
    _vm.data.class
  ]},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }