import tailwindConfig from "../../tailwind.config.js";
import { TagInterface } from "./tag.js";
import { CategoryInterface } from "./category";
import { TrainingTeaserInterface } from "./training";
import { CheatSheetListInterface } from "./cheat-sheet.js";

export type TAILWIND_COLOR_KEYS = keyof typeof tailwindConfig.theme.extend.colors;

export enum ContentType {
  LearningTopic = "learning-topic",
  Toolkit = "toolkit"
}

export interface LearningTopicTeaserInterface {
  id: string;
  title: string;
  illustrationUrl: string;
  primaryColor: TAILWIND_COLOR_KEYS;
  shortDescription: string;
  tags: TagInterface[];
  type: ContentType;
}

export interface LearningTopicInterface extends LearningTopicTeaserInterface {
  learningContext: string;
  shortDescription: string;
  learningGoals: string[];
  trainingListTitle: string;
  headerIllustrationUrl: string;
  thumbnailUrl: string;
  cheatSheets: CheatSheetListInterface[];
}

export interface LearningTopicList {
  id: string;
  title: string;
  scrollable: boolean;
  tags: TagInterface[];
}

export interface LearningTopicsSection extends LearningTopicList {
  topics: LearningTopicInterface[];
}

export interface LearningTopicPage extends LearningTopicInterface {
  categories: CategoryInterface[];
  trainings: TrainingTeaserInterface[];
}

export interface LearningTopicHub {
  id: string;
  title?: string;
  learningTopicLists: LearningTopicList[];
}

export interface LearningTopicState {
  learningTopicHub: LearningTopicHub;
  learningTopics: LearningTopicInterface[];
}
