
























import { defineComponent } from "@/composition-api";

export default defineComponent({
  props: {
    topFill: {
      type: String,
      default: "black"
    },
    bottomFill: {
      type: String,
      default: "#FF0000"
    }
  }
});
