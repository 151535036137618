import { ApiEndpoint } from "../endpoint";
import {
  LearningTopicResponseTranslator,
  SingleLearningTopicResponseTranslator,
  LearningTopicHubResponseTranslator
} from "../translators/learning-topic";
import { DrupalJsonApiParams } from "drupal-jsonapi-params";
import useApi from "@/composition/useApi";

export class LearningTopicHttp {
  static async getLearningTopicNavigation() {
    const { client } = useApi();
    const LTApiParams = new DrupalJsonApiParams();
    LTApiParams.addFields("node--learning_topic", ["title"]);
    const queryString = LTApiParams.getQueryString({ encode: false });
    const { data } = await client.get(
      `${ApiEndpoint.LearningTopic}?${queryString}`
    );
    return data;
  }

  static async getLearningTopicHub() {
    const { client } = useApi();
    const apiParams = new DrupalJsonApiParams();
    apiParams.addInclude(["field_learning_topic_lists.field_filter_tags"]);
    const queryString = apiParams.getQueryString({ encode: false });

    const data = await client.get(
      `${ApiEndpoint.LearningTopicHub}?${queryString}`
    );

    const learningTopicHub = LearningTopicHubResponseTranslator(data);
    return learningTopicHub;
  }

  static async getLearningTopics(filters = [], sort = null) {
    const { client } = useApi();
    const apiParams = new DrupalJsonApiParams();
    apiParams.addInclude([
      "field_tags",
      "field_teaser_svg",
      "field_header_svg",
      "field_thumbnail_svg",
      "field_cheat_sheet_sections.field_cheat_sheets.field_image"
    ]);
    if (filters.length) {
      filters.forEach(filter => {
        apiParams.addFilter(filter.path, filter.value, filter.operator);
      });
    }
    if (sort) {
      apiParams.addSort(sort.path, sort.direction || "DESC");
    }
    const queryString = apiParams.getQueryString({ encode: false });

    const data = await client.get(
      `${ApiEndpoint.LearningTopic}?${queryString}`
    );

    const learningTopics = LearningTopicResponseTranslator(data);
    return learningTopics;
  }

  static async getLearningTopic(topicId) {
    const { client } = useApi();
    const apiParams = new DrupalJsonApiParams();
    apiParams.addInclude([
      "field_tags",
      "field_teaser_svg",
      "field_header_svg",
      "field_cheat_sheet_sections.field_cheat_sheets.field_image"
    ]);
    const queryString = apiParams.getQueryString({ encode: false });

    const data = await client.get(
      `${ApiEndpoint.LearningTopic}/${topicId}?${queryString}`
    );

    const learningTopic = SingleLearningTopicResponseTranslator(data);
    return learningTopic;
  }
}
