import Vue from "vue";
import { watchEffect, ref } from "@vue/composition-api";

export default () => {
  // Gets the template with the ref="content" attribute
  const content = ref(null);
  let numberOfImages = 0;

  watchEffect(
    () => {
      if (content.value) {
        // Waits for all images to load before opening blocking print dialog
        // In Chrome, if the print dialog opens too quickly, the images are not loaded.
        Vue.nextTick(() => {
          numberOfImages = Array.from(document.querySelectorAll("img")).length;
          if (numberOfImages === 0) {
            window.print();
          }
          Array.from(document.querySelectorAll("img")).forEach(img => {
            img.addEventListener("load", () => {
              numberOfImages -= 1;
              if (numberOfImages < 1) {
                window.print();
              }
            });
          });
        });
        // window.close(); // safari closes without waiting for the print dialog to pop up
      }
    },
    { flush: "post" }
  );

  return { content };
};
