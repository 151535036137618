















































import { defineComponent, reactive } from "@/composition-api";
import store from "@/store";
import useBreakpoints from "@/composition/useBreakpoints";
import DpaNavigationStatic from "./DpaNavigationStatic.vue";
import UiTransitionOpacity from "../ui/UiTransitionOpacity.vue";

export default defineComponent({
  components: {
    DpaNavigationStatic,
    UiTransitionOpacity
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },

  setup() {
    const { screen, isTablet, isDesktop } = useBreakpoints();
    const close = () => {
      store.commit("navigation/setIsLeftNavOpen", false);
    };

    const state = reactive({
      isTablet,
      isDesktop
    });

    return {
      state,
      close,
      isTablet,
      isDesktop,
      screen
    };
  }
});
