












import { PropType } from "vue";
import { defineComponent } from "@/composition-api";
import { Location } from "vue-router";
import useButtonStyles from "@/composition/useButtonStyles";

export default defineComponent({
  props: {
    size: {
      type: String,
      default: "medium",
      validator(val: string) {
        return ["small", "medium", "large"].includes(val);
      }
    },
    color: {
      type: String,
      default: "blue",
      validator(val: string) {
        return ["blue", "white", "gray"].includes(val);
      }
    },
    to: {
      type: Object as PropType<Location> | null,
      default: null
    },
    href: {
      type: String,
      default: ""
    }
  },
  computed: {
    path(): string {
      if (this.to) {
        const route = this.$router.resolve(this.to);
        return route.href;
      } else {
        return this.href;
      }
    }
  },
  setup(props) {
    const { variantClassName, sizeClassName } = useButtonStyles({
      size: props.size,
      color: props.color
    });

    return { variantClassName, sizeClassName };
  }
});
