


















import Vue, { PropType } from "vue";
import FcRichText from "@/components/FcRichText/FcRichText.vue";
import { CheatSheetListLayout } from "@/interfaces";

export default Vue.extend({
  components: {
    FcRichText
  },
  props: {
    src: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    layout: {
      type: String as PropType<CheatSheetListLayout>,
      default: CheatSheetListLayout.One
    }
  },
  computed: {
    layoutClass() {
      if (this.layout === CheatSheetListLayout.TwoThirdsLeft) {
        return "md:grid-cols-2 lg:grid-cols-3 print:grid-cols-2";
      }
      if (this.layout === CheatSheetListLayout.TwoThirdsRight) {
        return "md:grid-cols-2 lg:grid-cols-3 print:grid-cols-2";
      }
      return "md:grid-cols-2 print:grid-cols-3";
    },
    imageContainerClasses() {
      const classes = [];
      if (this.layout !== CheatSheetListLayout.One) {
        classes.push("md:col-span-2 print:col-span-2");
      }
      if (this.layout === CheatSheetListLayout.TwoThirdsRight) {
        classes.push("lg:col-start-2 print:col-start-2");
      }
      return classes;
    },
    imageClasses() {
      if (this.layout === CheatSheetListLayout.One) {
        return "w-3/4 md:w-full";
      } else {
        return "w-full";
      }
    },
    contentClasses() {
      if (this.layout === CheatSheetListLayout.TwoThirdsRight) {
        return "md:col-span-2 lg:col-span-1 lg:row-start-1 md:col-start-1 print:row-start-1 print:col-start-1";
      }
      if (this.layout === CheatSheetListLayout.TwoThirdsLeft) {
        return "md:col-span-2 lg:col-span-1";
      }
      return "";
    }
  }
});
