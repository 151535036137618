

























import Vue, { PropType } from "vue";
import DownloadIcon from "@/assets/vectors/icons/download.svg?inline";
import ExternalLink from "@/components/FcExternalLink/FcExternalLink.vue";
import UiCard from "@/components/ui/UiCard.vue";
import { Location } from "vue-router";

export default Vue.extend({
  components: {
    UiCard,
    ExternalLink,
    DownloadIcon
  },
  props: {
    thumbnailUrl: {
      type: String,
      required: true
    },
    thumbnailAlt: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      required: true
    },
    route: {
      type: Object as PropType<Location>,
      required: true
    },
    printRoute: {
      type: Object as PropType<Location>,
      required: true
    }
  }
});
