import debounce from "lodash.debounce";
import Vue from "vue";

export const redirect = debounce(
  async (href: string) => {
    await Vue.nextTick();
    window.location.assign(href);
  },
  200,
  { leading: true }
);
