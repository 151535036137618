








































import Vue, { PropType } from "vue";
import SearchIcon from "@/assets/vectors/icons/search.svg";
import FcSearchTagsOverlay from "./FcSearchTagsOverlay.vue";
import { TagInterface } from "@/interfaces";

export default Vue.extend({
  components: {
    SearchIcon,
    FcSearchTagsOverlay
  },
  props: {
    tags: {
      type: Array as PropType<TagInterface[]>,
      default: () => []
    }
  },
  data() {
    return {
      active: false,
      buttonRight: 0
    };
  },
  computed: {
    modalWidth(): number {
      return 656;
    },
    modalLeft(): number {
      return this.buttonRight - this.modalWidth;
    }
  },
  mounted() {
    const buttonRef = this.$refs["searchButton"] as HTMLElement | undefined;
    if (buttonRef) {
      this.buttonRight = buttonRef.getBoundingClientRect().right;
    }
    window.addEventListener("resize", () => {
      this.buttonRight = buttonRef.getBoundingClientRect().right;
    });
  }
});
