import { ContentType, LearningTopicInterface } from "@/interfaces";
import { CheatSheetLearningTopicResponseTranslator } from "./cheat-sheet";
import {
  getIncludedObjectAttributes,
  getIncludedObjectRelationships
} from "./utils";

const responseMapper = (topic, included): LearningTopicInterface => {
  const attr = topic.attributes;
  const {
    field_teaser_svg: fieldTeaserSvg,
    field_header_svg: fieldHeaderSvg,
    field_thumbnail_svg: fieldThumbnailSvg,
    field_tags: fieldTags
  } = topic.relationships;

  const illustrationUrl = getIncludedObjectAttributes(
    included,
    fieldTeaserSvg.data.id
  )?.uri.url;

  const headerIllustrationUrl = getIncludedObjectAttributes(
    included,
    fieldHeaderSvg.data.id
  )?.uri.url;

  const thumbnailUrl = getIncludedObjectAttributes(
    included,
    fieldThumbnailSvg.data.id
  )?.uri.url;

  const tags = fieldTags.data
    .map(tag => {
      const tagAttributes = getIncludedObjectAttributes(included, tag.id);
      if (!tagAttributes) return null;
      return {
        id: tag.id,
        label: tagAttributes.name
      };
    })
    .filter(Boolean);

  return {
    id: topic.id,
    title: attr.title,
    shortDescription: attr.field_short_description,
    illustrationUrl,
    headerIllustrationUrl,
    thumbnailUrl,
    primaryColor: attr.field_accent_color,
    learningContext: attr.field_introduction_text.processed,
    learningGoals: attr.field_learning_goals,
    trainingListTitle: attr.field_training_teaser_list_title,
    tags,
    cheatSheets: CheatSheetLearningTopicResponseTranslator(topic, included),
    type: ContentType.LearningTopic
  };
};

export const LearningTopicResponseTranslator = (
  response
): LearningTopicInterface[] => {
  const { included, data } = response;

  const learningTopics = data.map(topic => responseMapper(topic, included));

  return learningTopics;
};

export const SingleLearningTopicResponseTranslator = (
  response
): LearningTopicInterface => {
  const { included, data } = response;
  return responseMapper(data, included);
};

export const LearningTopicHubResponseTranslator = response => {
  const { included, data } = response;
  const [page] = data;
  const { attributes, relationships } = page;
  return {
    id: page.id,
    title: attributes.title,
    learningTopicLists: relationships.field_learning_topic_lists.data.map(
      list => {
        const listAttributes = getIncludedObjectAttributes(included, list.id);
        const listRelationships = getIncludedObjectRelationships(
          included,
          list.id
        );

        return {
          id: list.id,
          title: listAttributes.field_title,
          scrollable: listAttributes.field_list_style === "slider",
          tags: listRelationships.field_filter_tags.data
            .map(tag => {
              const tagAttributes = getIncludedObjectAttributes(
                included,
                tag.id
              );
              if (!tagAttributes) return null;

              return {
                id: tag.id,
                label: tagAttributes.name
              };
            })
            .filter(Boolean)
        };
      }
    )
  };
};
