







import FcCheckmark from "@/assets/vectors/icons/fc-checkmark.svg?inline";
import Vue from "vue";
export default Vue.extend({
  components: {
    FcCheckmark
  }
});
