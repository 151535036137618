











import { defineComponent } from "@vue/composition-api";
import DpaFaktencheckLogo from "@/components/nav/DpaFaktencheckLogo.vue";
import ApaLogo from "@/components/nav/ApaLogo.vue";

export default defineComponent({
  components: {
    DpaFaktencheckLogo,
    ApaLogo
  }
});
