


















import { defineComponent, computed, ref } from "@/composition-api";
import FcPracticalExamplesTeaserList from "@/components/FcPracticalExamplesTeaserList/FcPracticalExamplesTeaserList.vue";
import FcInteractiveContet from "@/components/FcInteractiveContent/FcInteractiveContent.vue";

export default defineComponent({
  props: {
    trainingId: {
      type: String,
      default: ""
    }
  },
  components: {
    "interactive-content": FcInteractiveContet,
    "practical-examples-teaser-list": FcPracticalExamplesTeaserList
  },
  setup(props, { root }) {
    const isLoading = ref(true);

    root.$store
      .dispatch("practicalExample/fetchPracticalExamplesPage")
      .finally(() => {
        isLoading.value = false;
      });

    const examplesPage = computed(() => {
      const page = root.$store.getters["practicalExample/currentPage"];
      if (isLoading.value) {
        return {
          lists: [
            {
              trainings: Array(6)
                .fill({})
                .map(() => ({
                  id: String(Math.floor(Math.random() * 999))
                }))
            }
          ]
        };
      }
      return page;
    });

    root.$store.dispatch("training/getVisistedTrainingStatus");

    const content = computed(() =>
      root.$store.getters["practicalExample/content"](props.trainingId)
    );

    const visitedTrainingIds = computed(
      () => root.$store.state.training.visitedTrainingIds
    );

    return {
      examplesPage,
      visitedTrainingIds,
      content,
      isLoading
    };
  }
});
