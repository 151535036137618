





























import { Routes } from "@/router/types";
import Vue from "vue";
import { Location } from "vue-router";
import UiCard from "@/components/ui/UiCard.vue";
import ArrowNavRight from "@/assets/vectors/icons/arrow-nav-right.svg";
import FcRichText from "../FcRichText/FcRichText.vue";

export default Vue.extend({
  components: {
    UiCard,
    ArrowNavRight,
    FcRichText
  },
  props: {
    id: {
      type: String,
      required: true
    },
    illustrationUrl: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    shortDescription: {
      type: String,
      default: ""
    }
  },
  computed: {
    headerStyles(): object {
      return {
        "background-image": this.illustrationUrl
          ? `url(${this.illustrationUrl})`
          : null
      };
    },
    route(): Location {
      let routeName = Routes.ToolDetail;
      if (this.id === "summary") {
        routeName = Routes.Summary;
      }
      return {
        name: routeName,
        params: {
          id: this.id
        }
      };
    }
  }
});
