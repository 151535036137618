




















import { defineComponent, computed } from "@/composition-api";
import useBreakpoints from "@/composition/useBreakpoints";

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false
    },
    left: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const { isMobile } = useBreakpoints();

    const computedStyles = computed(() => {
      if (!isMobile.value) {
        return {
          left: `${props.left}px`,
          width: `${props.width}px`
        };
      } else {
        return {
          left: 0,
          width: "calc(100% - 3rem)",
          margin: `1.5rem`
        };
      }
    });

    return {
      computedStyles
    };
  }
});
