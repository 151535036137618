


















































import { defineComponent } from "@vue/composition-api";
import UiInput from "@dpa-id-components/ui-input";
import UiButton from "@dpa-id-components/ui-button";
import FcPublicHeader from "./lib/FcPublicHeader.vue";
import FcPublicFooter from "./lib/FcPublicFooter.vue";
import FcPublicCard from "./lib/FcPublicCard.vue";
import ExclamationIcon from "@/assets/vectors/icons/exclamation.svg?inline";
import store from "@/store";

export default defineComponent({
  components: {
    UiButton,
    UiInput,
    FcPublicHeader,
    FcPublicFooter,
    FcPublicCard,
    ExclamationIcon
  },

  data() {
    return {
      email: "",
      errorMessage: "",
      emailSent: false
    };
  },

  methods: {
    validate() {
      this.errorMessage = "";
      let isValid = this.email.length > 0;

      // regex email
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

      if (!this.email || !emailRegex.test(this.email)) {
        this.errorMessage = this.$t("auth.invalidEmail") as string;
        isValid = false;
      }

      return isValid;
    },

    submit() {
      if (!this.validate()) return;

      store.dispatch("auth/forgotPassword", { email: this.email }).then(() => {
        // show successful state
        this.emailSent = true;
      });
    }
  }
});
