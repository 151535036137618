import Vue from "vue";
import VueI18n from "vue-i18n";
import messages from "./messages.json";

// See https://kazupon.github.io/vue-i18n/started.html
Vue.use(VueI18n);

export default new VueI18n({
  fallbackLocale: "en",
  fallbackRoot: true,
  locale: "de",
  silentTranslationWarn: process.env.NODE_ENV === "production",
  silentFallbackWarn: true, // process.env.NODE_ENV === "production" // See issue: https://github.com/kazupon/vue-i18n/issues/689,
  messages
});
