import { CheatSheetInterface, CheatSheetListInterface } from "@/interfaces";
import {
  getIncludedObjectAttributes,
  getIncludedObjectRelationships
} from "./utils";

const responseMapper = (cheatSheet, included): CheatSheetInterface => {
  const attr = cheatSheet.attributes;
  const { field_image: fieldImage } = cheatSheet.relationships;

  const imageUrl = getIncludedObjectAttributes(included, fieldImage.data.id)
    ?.uri.url;

  return {
    id: cheatSheet.id,
    title: attr.name,
    src: imageUrl,
    alt: fieldImage.data.meta.alt,
    description: attr.field_description.processed
  };
};

export const cheatSheetMapper = (included, id): CheatSheetInterface => {
  const cheatSheet = getIncludedObjectAttributes(included, id);
  if (!cheatSheet) return {} as CheatSheetInterface;

  const { field_image: fieldImage } = getIncludedObjectRelationships(
    included,
    id
  );

  const fieldImageAttr = getIncludedObjectAttributes(
    included,
    fieldImage.data.id
  );

  return {
    id,
    title: cheatSheet.name,
    src: fieldImageAttr?.uri?.url,
    alt: fieldImage.data.meta.alt,
    description: cheatSheet.field_description.processed
  };
};

export const CheatSheetResponseTranslator = (
  response
): CheatSheetInterface[] => {
  const { included, data } = response;

  if (!data) return [];

  const cheatSheets = data.map(cheatSheet =>
    responseMapper(cheatSheet, included)
  );

  return cheatSheets;
};

export const CheatSheetLearningTopicResponseTranslator = (
  learningTopicResponse,
  included
): CheatSheetListInterface[] => {
  const {
    field_cheat_sheet_sections: fieldCheatSheetSections
  } = learningTopicResponse.relationships;
  const sections = fieldCheatSheetSections.data.map(({ id }) => {
    const section = getIncludedObjectAttributes(included, id);
    const {
      field_cheat_sheets: fieldCheatSheets
    } = getIncludedObjectRelationships(included, id);

    return {
      id,
      layout: section.field_layout,
      listTitle: section.field_section_headline,
      entries: fieldCheatSheets.data.map(({ id }) =>
        cheatSheetMapper(included, id)
      )
    };
  });

  return sections;
};
