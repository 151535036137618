














import { defineComponent } from "@/composition-api";
import ApaLogo from "@/components/nav/ApaLogo.vue";

export default defineComponent({
  components: {
    ApaLogo
  }
});
