







import Vue, { PropType } from "vue";
import FcVideoPlayer from "@/components/FcVideoPlayer/FcVideoPlayer.vue";
import FcEmbedQuiz from "@/components/FcEmbedQuiz/FcEmbedQuiz.vue";
import { InteractiveContentInterface, TrainingInterface } from "@/interfaces";

export default Vue.extend({
  components: {
    FcVideoPlayer,
    FcEmbedQuiz
  },
  props: {
    content: {
      type: Object as PropType<TrainingInterface | InteractiveContentInterface>,
      required: true
    }
  },
  computed: {
    isVideoPlayer() {
      return "video" in this.content;
    }
  }
});
