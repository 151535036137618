
























import { defineComponent } from "@/composition-api";
import { PropType } from "vue";
import { TagInterface } from "@/interfaces";
import UiModal from "../ui/UiModal.vue";
import FcTag from "../FcTag/FcTag.vue";

export default defineComponent({
  components: {
    UiModal,
    FcTag
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    left: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 0
    },
    tags: {
      type: Array as PropType<TagInterface[]>,
      default: () => []
    }
  }
});
