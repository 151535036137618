import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import useAuthentication from "./useAuthentication";

export default () => {
  const { drupalJWT } = useAuthentication();

  const client = Axios.create({
    baseURL: "/api"
  });

  client.interceptors.request.use((config: AxiosRequestConfig) => {
    config.headers["JWT-Authorization"] = `Bearer ${drupalJWT.value}`;
    return config;
  });

  client.interceptors.response.use(
    (response: AxiosResponse) => response.data,
    (error: AxiosError) => {
      console.log(error);
      if (error.response.status === 401) {
        return Promise.reject(error);
      }

      return Promise.reject(error);
    }
  );

  return { client };
};
