


























































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    dark: {
      type: Boolean,
      default: false
    }
  }
});
