















import Vue, { PropType } from "vue";
import { VideoChapter } from "../../../interfaces/video-player";
import FcVideoChapterButton from "./FcVideoChapterButton/FcVideoChapterButton.vue";

export default Vue.extend({
  components: {
    "chapter-button": FcVideoChapterButton
  },
  props: {
    chapters: {
      type: Array as PropType<VideoChapter[]>,
      default: () => []
    }
  }
});
