






import Vue from "vue";
import UiGrid from "@/components/ui/UiGrid.vue";
import ToolkitTeaser from "@/components/FcToolkitTeaser/FcToolkitTeaser.vue";
import ToolkitTeaserSkeleton from "@/components/FcToolkitTeaser/FcToolkitTeaserSkeleton.vue";

export default Vue.extend({
  components: {
    UiGrid,
    ToolkitTeaser,
    ToolkitTeaserSkeleton
  },
  props: {
    teasers: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    component(): string {
      const baseComponent = "toolkit-teaser";
      return this.loading ? `${baseComponent}-skeleton` : baseComponent;
    }
  }
});
