






































import { defineComponent } from "@/composition-api";
import useBreakpoints from "@/composition/useBreakpoints";
import DpaHeader from "@/components/nav/DpaHeader.vue";
import DpaNavigation from "@/components/nav/DpaNavigation.vue";
import FcSideMenu from "@/components/FcSideMenu/FcSideMenu.vue";
import FcMenuFooter from "@/components/FcMenuFooter/FcMenuFooter.vue";
import store from "@/store";
import { computed, ref, watch } from "@vue/composition-api";
import { Routes } from "./router/types";

export default defineComponent({
  name: "App",
  components: {
    DpaHeader,
    DpaNavigation,
    FcSideMenu,
    FcMenuFooter
  },
  setup(props, { root }) {
    const { isMobile, isTablet, isDesktop } = useBreakpoints();

    const drupalJWT = computed(() => store.state.auth.drupalJWT);
    const dpaJWT = computed(() => store.state.auth.dpaJWT);

    const isLeftNavOpen = computed(() => store.state.navigation.isLeftNavOpen);
    const menuItems = computed(() => store.state.navigation.items);
    const footerLinks = computed(() => store.state.navigation.footerLinks);
    const isSearchOpen = ref(false);
    const toggleMenu = () => {
      store.commit("navigation/setIsLeftNavOpen", !isLeftNavOpen.value);
    };

    const fetchTagsAndNavigation = () => {
      if (drupalJWT.value) {
        store.dispatch("navigation/getNavigationItems");
        store.dispatch("tag/fetchAvailableTags");
      }
    };

    watch([drupalJWT, dpaJWT], fetchTagsAndNavigation, {
      immediate: true
    });

    const isAuthRoute = computed(() =>
      [Routes.Login, Routes.ForgotPassword, Routes.ResetPassword].includes(
        root.$route.name as Routes
      )
    );

    return {
      isDesktop,
      isTablet,
      isMobile,
      isLeftNavOpen,
      isSearchOpen,
      toggleMenu,
      menuItems,
      footerLinks,
      isAuthRoute,
      isLoggedIn: drupalJWT
    };
  },
  methods: {
    /**
     * Returns padding classes for the content viewport. This is the central
     * wrapper and no child elements should need to make assumptions about
     * certain ui features (navigation, search, audio player) beeing visible.
     * @returns {Array<String>} Am array of tailwind padding classes
     */
    // TODO: Add bottom padding based on audio player visible state.
    getViewportPadding({ isMobile, isTablet, isDesktop, isSearchOpen }) {
      let classes = {};

      if (isMobile || isTablet) {
        classes = {
          top: isSearchOpen ? "pt-32" : "pt-16"
        };
      }

      if (isDesktop) {
        classes = {
          left: this.isLoggedIn ? "pl-64 print:pl-0" : "",
          top: "pt-16 print:pt-0"
        };
      }

      return [Object.values(classes)];
    },

    closeSideMenu() {
      this.$store.commit("navigation/setIsLeftNavOpen", false);
    }
  }
});
