


















import Vue, { PropType } from "vue";
import { Route } from "vue-router";
import NavItemLink from "./NavItemLink.vue";

export default Vue.extend({
  components: {
    NavItemLink
  },
  props: {
    label: {
      type: String,
      required: true
    },
    to: {
      type: Object as PropType<Route>,
      default: () => ({})
    },
    external: {
      type: Boolean,
      default: false
    },
    href: {
      type: String,
      default: ""
    }
  }
});
