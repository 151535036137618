<template>
  <!-- ui-input -->
  <div class="relative">
    <label
      :class="labelClassNames"
      :for="id"
      class="absolute px-1 transition-all duration-150 origin-left transform pointer-events-none select-none left-3"
      >{{ label }}</label
    >
    <input
      :id="id"
      ref="input"
      :value="value"
      :readonly="readonly"
      class="block w-full text-gray-900 placeholder-gray-400 transition duration-150 rounded-none outline-none h-14 hover:border-blue"
      :class="isFocused ? 'border-2 border-blue p-inputFocused' : 'border p-4'"
      :type="type"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      @input="updateValue"
      @focus="handleFocus"
      @blur="handleBlur"
      @keyup="event => $emit('keyup', event)"
    />
    <ui-icon-chevron-down
      v-if="chevron"
      class="absolute w-6 h-6 text-gray-900 pointer-events-none fill-current right-4 top-4"
    />
  </div>
</template>
<script>
import { defineComponent } from "@/composition-api";
import { v4 as uuidv4 } from "uuid";
import UiIconChevronDown from "@/assets/vectors/icons/chevron-down.svg?inline";

export default defineComponent({
  components: { UiIconChevronDown },
  props: {
    value: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    chevron: {
      default: false
    },
    readonly: {
      default: false
    },
    autocomplete: {
      default: "on"
    },
    type: {
      type: String,
      default: "text"
    }
  },
  data() {
    return {
      isFocused: false,
      id: `id-${uuidv4()}`
    };
  },
  computed: {
    /**
     * @returns {Boolean}
     */
    isFilled() {
      return this.value !== "";
    },
    /**
     * @returns {String}
     */
    labelClassNames() {
      let result = "";

      if (this.isFocused || this.isFilled) {
        result = "-translate-y-1/2 bg-white text-xs";

        if (this.isFocused) {
          result += " text-blue";
        }

        if (this.isFilled) {
          result += " text-gray-500";
        }
      } else if (this.placeholder) {
        result = "-translate-y-1/2 bg-white text-xs text-gray-700";
      } else {
        result = "text-gray-500 translate-y-4 text-sm";
      }

      return result;
    }
  },
  methods: {
    updateValue(event) {
      this.$emit("input", event.target.value);
    },
    handleFocus(event) {
      this.isFocused = true;
      this.$emit("focus", event);
    },
    handleBlur(event) {
      this.isFocused = false;
      this.$emit("blur", event);
    }
  },
  mounted() {
    if (this.autofocus) {
      this.$refs.input.focus();
    }
  }
});
</script>
<style scoped>
.p-inputFocused {
  @apply py-4;
  padding-left: 15px;
  padding-right: 15px;
}
</style>
