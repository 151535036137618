













import { Location } from "vue-router";
import { Routes } from "@/router/types";
import { TagInterface, TagTypeEnum } from "@/interfaces";
import UiBadge from "../ui/UiBadge.vue";
import Vue, { PropType } from "vue";

export default Vue.extend({
  components: {
    UiBadge
  },
  props: {
    tag: {
      type: Object as PropType<TagInterface>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes(): string {
      return this.active ? "bg-blue" : "bg-gray-700 hover:bg-blue-dark";
    },
    to(): Location | string {
      if (this.active) return "#";

      return {
        name:
          this.tag.type === TagTypeEnum.LearningTopic
            ? Routes.LearningTopicDetails
            : Routes.Tags,
        params: {
          id: this.tag.id
        }
      };
    },
    active(): boolean {
      if (this.$route?.query?.tag) {
        return this.$route.query.tag === this.tag.id;
      }
      if (this.$route.name === Routes.Tags) {
        return this.$route.params.id === this.tag.id;
      }
      return false;
    }
  }
});
