import { cheatSheetMapper } from "./cheat-sheet";
import {
  ToolHubPage,
  ToolInterface,
  ToolkitPage,
  ToolListPage,
  ToolListSectionInterface,
  CheatSheetListInterface
} from "@/interfaces";
import {
  getIncludedObjectAttributes,
  getIncludedObjectRelationships
} from "./utils";

const mapCheatSheetSections = (
  cheatSheetSections,
  included
): CheatSheetListInterface[] => {
  return cheatSheetSections.map(cs => {
    const {
      field_cheat_sheets: { data }
    } = getIncludedObjectRelationships(included, cs.id);
    const cheatSheets = data.map(({ id }) => cheatSheetMapper(included, id));
    return {
      id: cs.id,
      layout: cs.field_layout,
      listTitle: cs.field_section_headline,
      entries: cheatSheets
    };
  });
};

const mapToolListSections = (
  toolListSections,
  included
): ToolListSectionInterface[] => {
  return toolListSections.map(
    (toolkit): ToolListSectionInterface => {
      const {
        field_filter_tags: { data: fieldFilterTags }
      } = getIncludedObjectRelationships(included, toolkit.id);

      return {
        id: toolkit.id,
        title: toolkit.field_section_headline,
        tagsIds: fieldFilterTags.map(({ id }) => id)
      };
    }
  );
};

const hubResponseMapper = (page, included): ToolHubPage => {
  const { attributes, relationships } = page;

  const {
    field_teaser_svg: { data: teaserSvgResponse },
    field_tags: { data: fieldTags }
  } = relationships;

  let teaserSvg = {} as { uri?: { url?: string } };
  if (teaserSvgResponse.id) {
    teaserSvg = {
      id: teaserSvgResponse.id,
      ...getIncludedObjectAttributes(included, teaserSvgResponse.id)
    };
  }

  const tags = fieldTags
    .map(({ id }) => {
      const attributes = getIncludedObjectAttributes(included, id);
      if (!attributes) return null;
      return {
        id,
        label: attributes.name
      };
    })
    .filter(Boolean);

  return {
    id: page.id,
    title: attributes.title,
    description: attributes.field_introduction_text.processed,
    shortDescription: attributes.field_short_description,
    teaserIllustrationUrl: teaserSvg?.uri?.url,
    primaryColor: attributes.field_accent_color,
    tags
  };
};

const pageResponseMapper = (page, included): ToolListPage => {
  const { attributes, relationships } = page;

  const {
    field_header_svg: { data: headerSvgResponse },
    field_teaser_svg: { data: teaserSvgResponse },
    field_thumbnail_svg: { data: thumbnailSvgResponse },
    field_cheat_sheet_sections: { data: cheatSheetSectionsResponse },
    field_tabs: { data: tabsResponse },
    field_tool_list_sections: { data: toolListSectionsResponse },
    field_tags: { data: fieldTags }
  } = relationships;

  const relationshipObjects = [
    headerSvgResponse,
    teaserSvgResponse,
    thumbnailSvgResponse,
    cheatSheetSectionsResponse,
    toolListSectionsResponse,
    tabsResponse
  ].map(relation => {
    if (Array.isArray(relation)) {
      return relation.map(item => ({
        id: item.id,
        ...getIncludedObjectAttributes(included, item.id)
      }));
    }
    if (relation.id) {
      return {
        id: relation.id,
        ...getIncludedObjectAttributes(included, relation.id)
      };
    }
  });

  const [
    headerSvg,
    teaserSvg,
    thumbnailSvg,
    cheatSheetSections,
    toolListSections,
    tabs
  ] = relationshipObjects;

  const tags = fieldTags
    .map(({ id }) => {
      const attributes = getIncludedObjectAttributes(included, id);
      if (!attributes) return null;
      return {
        id,
        label: attributes.name
      };
    })
    .filter(Boolean);

  return {
    id: page.id,
    title: attributes.title,
    description: attributes.field_introduction_text.processed,
    shortDescription: attributes.field_short_description,
    headerIllustrationUrl: headerSvg?.uri?.url,
    teaserIllustrationUrl: teaserSvg?.uri?.url,
    thumbnailUrl: thumbnailSvg?.uri?.url,
    cheatSheetSections: mapCheatSheetSections(cheatSheetSections, included),
    toolListSections: mapToolListSections(toolListSections, included),
    tabs,
    toolLists: [],
    primaryColor: attributes.field_accent_color,
    tags
  };
};

const toolResponseMapper = (tool, included): ToolInterface => {
  const { attributes, relationships } = tool;
  const {
    field_icon: { data: fieldIcon },
    field_type: { data: fieldType },
    field_tags: { data: fieldTags },
    field_learning_topic: { data: fieldLearningTopics }
  } = relationships;

  const icon = getIncludedObjectAttributes(included, fieldIcon.id);
  const type = getIncludedObjectAttributes(included, fieldType.id);
  const tags = fieldTags
    .map(({ id }) => {
      const attributes = getIncludedObjectAttributes(included, id);
      if (!attributes) return null;
      return {
        id,
        label: attributes.name
      };
    })
    .filter(Boolean);

  return {
    id: tool.id,
    src: icon?.uri?.url,
    alt: fieldIcon.meta.alt,
    title: attributes.name,
    description: attributes.field_short_description,
    link: attributes.field_url.uri,
    tag: {
      id: fieldType.id,
      label: type.name,
      description: type.description.processed
    },
    content: {
      application: attributes.field_application.processed,
      knowledge: attributes.field_knowledge.processed,
      usefulness: attributes.field_usefulness.processed
    },
    learningTopicIds: fieldLearningTopics.map(({ id }) => id),
    tags: tags
  };
};

export const ToolHubResponseTranslator = (response): ToolHubPage[] => {
  const { included, data } = response;
  if (!data) return [];

  return data.map(page => hubResponseMapper(page, included));
};

export const ToolListResponseTranslator = (response): ToolkitPage => {
  const { included, data } = response;
  if (!data) return {} as ToolkitPage;

  return pageResponseMapper(data, included);
};

export const ToolListPagesResponseTranslator = (response): ToolkitPage[] => {
  const { included, data } = response;
  if (!data) return [];

  return data.map(page => pageResponseMapper(page, included));
};

export const ToolsResponseTranslator = (response): ToolInterface[] => {
  const { included, data } = response;
  if (!data) return [];

  return data.map(tool => toolResponseMapper(tool, included));
};
