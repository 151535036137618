import { LearningTopicTeaserInterface } from "./learning-topic";
import { PracticalExampleTeaser } from "./practical-example";
import { ToolInterface } from "./tool";

export enum TagTypeEnum {
  Tag = "tag",
  LearningTopic = "learning-topic"
}

export interface TagInterface {
  id: string;
  label: string;
  type?: TagTypeEnum;
}

export interface TypeTagInterface extends TagInterface {
  description: string;
}

export interface TagState {
  tags: TagInterface[];
  learningTopics: LearningTopicTeaserInterface[];
  trainings: PracticalExampleTeaser[];
  tools: ToolInterface[];
}
