export enum Routes {
  LearningTopicHubPage = "learning-topic__hub-page",
  LearningTopicDetails = "learning-topic__details",
  LearningTopicTrainings = "learning-topic__trainings",
  LearningTopicTools = "learning-topic__tools",
  PracticalExamples = "practical-examples",
  ToolsHub = "tools",
  ToolDetail = "tools__detail",
  Legal = "legal",
  LegalTermsOfService = "terms-of-service",
  LegalPrivacyPolicy = "privacy-poliy",
  Summary = "summary",
  PrintLearningTopicTools = "learning-topic__tools__print",
  PrintToolDetail = "tools__detail__print",
  Tags = "tags",
  Login = "login",
  ForgotPassword = "forgot-password",
  ResetPassword = "reset-password"
}
