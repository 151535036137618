import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Authenticate from "../views/Authenticate.vue";
import Unauthorized from "../views/Unauthorized.vue";
import LearningTopicHub from "../views/LearningTopicHub.vue";
import LearningTopicDetail from "../views/LearningTopicDetails.vue";
import LearningTopicTrainingsPage from "../views/LearningTopicTrainingsPage.vue";
import LearningTopicTools from "../views/LearningTopicTools.vue";
import PracticalExamples from "../views/PracticalExamples.vue";
import Legal from "../views/Legal.vue";
import LegalTermsOfService from "../views/LegalTermsOfService.vue";
import LegalPrivacyPolicy from "../views/LegalPrivacyPolicy.vue";
import ToolsHub from "../views/ToolsHub.vue";
import ToolListPage from "../views/ToolListPage.vue";
import SummaryPage from "../views/SummaryPage.vue";
import TagBrowser from "../views/TagBrowser.vue";
import { Routes } from "./types";
import multiguard from "vue-router-multiguard";
import { getGuardSequence } from "./navigationGuards";
import Login from "../views/Login.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import ResetPassword from "../views/ResetPassword.vue";

Vue.use(VueRouter);

export const routes: Array<RouteConfig> = [
  {
    name: "Authenticate",
    path: "/authenticate",
    component: Authenticate
  },
  {
    name: "Unauthorized",
    path: "/unauthorized",
    component: Unauthorized
  },
  {
    path: "/",
    redirect: "/learning-topics"
  },
  {
    path: "/learning-topics",
    name: Routes.LearningTopicHubPage,
    meta: {
      title: "dpa-Factify"
    },
    component: LearningTopicHub
  },
  {
    path: "/learning-topic/:id",
    name: Routes.LearningTopicDetails,
    meta: {
      title: "dpa-Factify"
    },
    component: LearningTopicDetail,
    props: route => ({
      topicId: route.params.id
    })
  },
  {
    path: "/learning-topic/:id/trainings/:trainingId",
    name: Routes.LearningTopicTrainings,
    meta: {
      title: "dpa-Factify"
    },
    component: LearningTopicTrainingsPage,
    props: route => ({
      topicId: route.params.id,
      trainingId: route.params.trainingId
    })
  },
  {
    path: "/learning-topic/:id/tools",
    name: Routes.LearningTopicTools,
    meta: {
      title: "dpa-Factify"
    },
    component: LearningTopicTools,
    props: route => ({ topicId: route.params.id })
  },
  {
    path: "/practical-examples",
    name: Routes.PracticalExamples,
    meta: {
      title: "dpa-Factify"
    },
    component: PracticalExamples,
    props: route => ({ trainingId: route.query.training })
  },
  {
    path: "/tools",
    name: Routes.ToolsHub,
    meta: {
      title: "dpa-Factify"
    },
    component: ToolsHub
  },
  {
    path: "/tool/summary",
    name: Routes.Summary,
    meta: {
      title: "dpa-Factify"
    },
    component: SummaryPage
  },
  {
    path: "/tool/:id",
    name: Routes.ToolDetail,
    meta: {
      title: "dpa-Factify"
    },
    component: ToolListPage,
    props: route => ({ toolId: route.params.id })
  },
  {
    path: "/print/tool/:id",
    name: Routes.PrintToolDetail,
    meta: {
      title: "dpa-Factify"
    },
    component: ToolListPage,
    props: route => ({ toolId: route.params.id, print: true })
  },
  {
    path: "/print/learning-topic/:id/tools",
    name: Routes.PrintLearningTopicTools,
    meta: {
      title: "dpa-Factify"
    },
    component: LearningTopicTools,
    props: route => ({ topicId: route.params.id, print: true })
  },
  {
    path: "/legal/:node",
    name: Routes.Legal,
    meta: {
      title: "dpa-Factify"
    },
    component: Legal,
    props: true
  },
  {
    path: "/terms-of-use",
    name: Routes.LegalTermsOfService,
    meta: {
      title: "dpa-Factify"
    },
    component: LegalTermsOfService
  },
  {
    path: "/privacy",
    name: Routes.LegalPrivacyPolicy,
    meta: {
      title: "dpa-Factify"
    },
    component: LegalPrivacyPolicy
  },
  {
    path: "/tags/:id",
    name: Routes.Tags,
    meta: {
      title: "dpa-Factify"
    },
    component: TagBrowser,
    props: true
  },
  {
    path: "/login",
    name: Routes.Login,
    meta: {
      title: "dpa-Factify"
    },
    component: Login
  },
  {
    path: "/forgot-password",
    name: Routes.ForgotPassword,
    meta: {
      title: "dpa-Factify"
    },
    component: ForgotPassword
  },
  {
    path: "/reset-password",
    name: Routes.ResetPassword,
    meta: {
      title: "dpa-Factify"
    },
    component: ResetPassword
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach(multiguard(getGuardSequence()));

router.afterEach(to => {
  Vue.nextTick(() => {
    document.title = to.meta?.title;
  });
});

export default router;
