













import { defineComponent } from "@/composition-api";
import useButtonStyles from "@/composition/useButtonStyles";

export default defineComponent({
  props: {
    size: {
      type: String,
      default: "medium",
      validator(val: string) {
        return ["small", "medium", "large"].includes(val);
      }
    },
    color: {
      type: String,
      default: "blue",
      validator(val: string) {
        return ["blue", "white", "gray"].includes(val);
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    to: {
      type: [Object, null],
      required: false,
      default: null
    }
  },
  computed: {
    tagName(): string {
      if (this.to !== null) {
        return "router-link";
      }
      return "button";
    }
  },
  setup(props) {
    const { variantClassName, sizeClassName } = useButtonStyles({
      disabled: props.disabled,
      size: props.size,
      color: props.color
    });

    return { variantClassName, sizeClassName };
  }
});
