

































import { defineComponent, computed } from "@/composition-api";
import { mapState } from "vuex";
import useBreakpoints from "@/composition/useBreakpoints";
import DpaFaktencheckLogo from "./DpaFaktencheckLogo.vue";
import UiIconMenu from "@/assets/vectors/icons/menu.svg?inline";
import UiIconSearch from "@/assets/vectors/icons/search.svg?inline";
import UiButtonIcon from "@/components/ui/UiButtonIcon.vue";
import UiTransitionOpacity from "@/components/ui/UiTransitionOpacity.vue";
import SearchTags from "@/components/FcSearchTags/FcSearchTags.vue";
import { TagInterface } from "@/interfaces";
import UserInfo from "@/components/FcUserInfo/FcInfoUser.vue";
import CooperationLogo from "./CooperationLogo.vue";
import useDpaSSO from "@/composition/useDpaSSO";

export default defineComponent({
  components: {
    DpaFaktencheckLogo,
    UiButtonIcon,
    UiIconMenu,
    UiIconSearch,
    UiTransitionOpacity,
    SearchTags,
    UserInfo,
    CooperationLogo
  },
  props: {
    hideToggleButton: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { useDpaId } = useDpaSSO();

    const { isMobile, isDesktop } = useBreakpoints();
    const toggleMenu = () => {
      emit("menuClick");
    };

    const showCooperationLogo = computed(() => {
      return !useDpaId.value;
    });

    return {
      isMobile,
      isDesktop,
      toggleMenu,
      showCooperationLogo
    };
  },
  computed: {
    ...mapState("tag", ["tags"]),
    sortedTags(): TagInterface[] {
      return [...(this.tags as TagInterface[])].sort((a, b) =>
        a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
      );
    }
  }
});
