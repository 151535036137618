




import { defineComponent } from "@vue/composition-api";
import FcFormLogin from "@/components/FcForm/FcFormLogin.vue";

export default defineComponent({
  components: {
    FcFormLogin
  }
});
