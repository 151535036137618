import qs from "query-string";
import store from "@/store";
import { Route } from "vue-router";
import { redirect } from "./lib/redirect";
import { tokenParser } from "./lib/tokenParser";
import { Routes } from "../types";
import useDpaSSO from "@/composition/useDpaSSO";

/**
 * Loads stage config if not already set in the store.
 *
 * @param to
 * @param from
 * @param next
 */
export const stageConfigGuard = async (to: any, from: any, next: any) => {
  if (!store.state.stageConfig.stage) {
    await store.dispatch("stageConfig/fetchStageConfig");
    next();
  } else {
    next();
  }
};

/**
 * Authenticates the user if the currenty URI contains a `ticket` URL parameter.
 *
 * @param to
 * @param from
 * @param next
 */
const jwtTicketGuard = async (to: any, from: any, next: any) => {
  const query = qs.parse(window.location.search);
  const { pubKey } = store.state.stageConfig;
  if (
    to.name === "Authenticate" &&
    query.ticket &&
    (await store.dispatch("auth/authenticate", {
      token: query.ticket,
      pubKey
    }))
  ) {
    // TODO: clean up: make intend revealing
    const isAuth = await store.dispatch("auth/authorize", {
      dpaJWT: query.ticket
    });
    if (isAuth) {
      return next(query.path);
    } else {
      return next("/unauthorized");
    }
  } else {
    next();
  }
};

/**
 * Redirects to single sign on service (SSO) if the user is not authenticated.
 */
const authenticatedGuard = async (to: any, from: any, next: any) => {
  if (!store.getters["auth/isAuthenticated"]) {
    const isLocal = store.state.stageConfig.stage === "LOCAL";
    const { origin, pathname } = !isLocal
      ? window.location
      : {
          origin: "http://app-local.dpa-faktencheck.test",
          pathname: window.location.pathname
        };
    const service = `${origin}/authenticate?path=${pathname}`;
    const { ssoURL } = store.state.stageConfig;
    const href = `${ssoURL}/login?service=${encodeURIComponent(service)}`;

    redirect(href);
  } else {
    next();
  }
};

/**
 * Redirects to unauthorized route if the user does not have permissions to
 * access content from drupal API.
 */
const authorizedGuard = async (to: Route, from: Route, next) => {
  if (!store.getters["auth/isAuthorized"] && to.path !== "/unauthorized") {
    next("/unauthorized");
  } else {
    next();
  }
};

/**
 * Redirects to unauthorized route if the user does not have permissions to
 * access content from drupal API.
 */
const expiredTokenGuard = async (to: Route, from: Route, next) => {
  if (to.path === "/unauthorized") return next();

  const token = store.state.auth.drupalJWT;

  if (!token) {
    await store.dispatch("auth/logout");
  }

  const { exp } = tokenParser(token);

  if (!exp) {
    await store.dispatch("auth/logout");
  } else {
    const expirationDate = new Date(exp * 1000);
    if (expirationDate > new Date()) {
      return next();
    }

    await store.dispatch("auth/authorize", store.state.auth);
    return next();
  }
  next({
    name: "Authenticate",
    query: { ticket: store.state.auth.dpaJWT, path: window.location.pathname }
  });
};

/**
 * Tests route against list of routes that do not need auth
 *
 * @param to Route
 * @returns bool
 */
const routeNeedsAuth = (to: Route) => {
  return ![
    Routes.Login,
    Routes.ForgotPassword,
    Routes.ResetPassword,
    Routes.Legal,
    Routes.LegalTermsOfService,
    Routes.LegalPrivacyPolicy
  ].includes(to.name as Routes);
};

/**
 *
 * @param to Route
 * @param from Route
 * @param next Function
 * @returns void
 */
const guestGuard = async (to: Route, from: Route, next) => {
  const token = store.state.auth.drupalJWT;
  if (to.name === Routes.Login && !!token) {
    return next({
      name: Routes.LearningTopicHubPage
    });
  }

  next();
};

/**
 *
 * @param to Route
 * @param from Route
 * @param next Function
 * @returns void
 */
const jwtGuard = async (to: Route, from: Route, next) => {
  if (!routeNeedsAuth(to)) return next();
  const token = store.state.auth.drupalJWT;
  if (!token) {
    return next({
      name: Routes.Login
    });
  }
  next();
};

/**
 *
 * @param to Route
 * @param from Route
 * @param next Function
 * @returns void
 */
const expiredJWTGuard = async (to: Route, from: Route, next) => {
  if (!routeNeedsAuth(to)) return next();

  const token = store.state.auth.drupalJWT;
  if (!token) {
    await store.dispatch("auth/logout");
  }

  const { exp } = tokenParser(token);
  if (!exp) {
    await store.dispatch("auth/logout");
  } else {
    const expirationDate = new Date(exp * 1000);
    if (expirationDate > new Date()) {
      return next();
    }

    store.dispatch("auth/resetTokens");
  }
  next({
    name: Routes.Login
  });
};

/**
 *
 * @param to Route
 * @param from Route
 * @param next Function
 * @returns void
 */
export const redirectAuthRoutesGuard = async (to: Route, from: Route, next) => {
  if (
    [Routes.Login, Routes.ForgotPassword, Routes.ResetPassword].includes(
      to.name as Routes
    )
  ) {
    // if user is still not logged in, this will end up redirecting to dpaid login
    return next({
      name: Routes.LearningTopicHubPage
    });
  }

  next();
};

export const getGuardSequence = () => {
  const { useDpaId } = useDpaSSO();

  if (!useDpaId.value) {
    return [guestGuard, jwtGuard, expiredJWTGuard];
  }

  // dpa guard sequence
  return [
    redirectAuthRoutesGuard,
    jwtTicketGuard,
    authenticatedGuard,
    authorizedGuard,
    expiredTokenGuard
  ];
};
