









































import { TypeTagInterface } from "@/interfaces";
import Vue, { PropType } from "vue";
import UiCard from "@/components/ui/UiCard.vue";
import FcTag from "@/components/FcTag/FcTag.vue";
import ChevronDownIcon from "@/assets/vectors/icons/chevron-down.svg";
import ExternalLinkIcon from "@/assets/vectors/icons/external-link.svg";
import ToolListItemContent from "./FcToolListItemContent/FcToolListItemContent.vue";

export default Vue.extend({
  components: {
    UiCard,
    FcTag,
    ChevronDownIcon,
    ExternalLinkIcon,
    ToolListItemContent
  },
  props: {
    src: {
      type: String,
      default: ""
    },
    alt: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ""
    },
    link: {
      type: String,
      default: ""
    },
    tag: {
      type: Object as PropType<TypeTagInterface>,
      required: true
    },
    content: {
      type: Object as PropType<{
        application: string;
        knowledge: string;
        usefullness: string;
      }>,
      required: true
    }
  },
  data() {
    return {
      showContent: false
    };
  },
  methods: {
    handleClick() {
      this.showContent = !this.showContent;
    }
  }
});
