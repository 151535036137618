<template functional>
  <div
    class="grid grid-cols-1 gap-4"
    :class="[
      data.class,
      data.staticClass,
      {
        'sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 print:grid-cols-3': !(
          Boolean(data.staticClass && data.staticClass.includes('grid')) ||
          Boolean(data.class && data.class.join('').includes('grid'))
        )
      }
    ]"
  >
    <slot v-for="item in props.items" :item="item"></slot>
  </div>
</template>

<script>
export default {};
</script>
