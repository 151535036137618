import { Routes } from "@/router/types";
import { LearningTopicHttp } from "./learning-topic.http";
import { ToolHttp } from "./tool.http";

export class NavigationHttp {
  static async getAllItems() {
    const items = [];
    const [learningTopics, toolkits] = await Promise.all([
      LearningTopicHttp.getLearningTopicNavigation(),
      ToolHttp.getToolkitsNavigation()
    ]);

    items.push({
      id: "LEARNING_TOPICS",
      route: Routes.LearningTopicDetails,
      children: learningTopics.map(nav => ({
        id: nav.id,
        title: nav.attributes.title
      }))
    });

    items.push({
      id: "CHEAT_SHEETS",
      route: Routes.ToolDetail,
      children: toolkits.map(nav => ({
        id: nav.id,
        title: nav.attributes.title
      }))
    });

    return items;
  }
}
