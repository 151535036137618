import { ActionTree } from "vuex";
import axios from "axios";
import { StageConfigState } from "./types";
import { RootState } from "../../types";

export const actions: ActionTree<StageConfigState, RootState> = {
  async fetchStageConfig({ commit }) {
    try {
      const config = await axios.get(`/config.json?t=${new Date().valueOf()}`);
      commit("setConfig", config);
      return true;
    } catch (error) {
      // console.error(error);
      return false;
    }
  }
};
