import { CategoryInterface } from "./category";
import { TAILWIND_COLOR_KEYS } from "./learning-topic";
import { ToolListInterface } from "./tool";

export enum CheatSheetListLayout {
  One = "1_1",
  TwoThirdsRight = "2_3_right",
  TwoThirdsLeft = "2_3_left"
}

export interface CheatSheetInterface {
  id: string;
  title: string;
  src: string;
  alt: string;
  description: string;
}

export interface CheatSheetListInterface {
  id: string;
  layout: CheatSheetListLayout;
  listTitle: string;
  entries: CheatSheetInterface[];
}

export interface CheatSheetPageInterface {
  title: string;
  illustrationUrl: string;
  primaryColor: TAILWIND_COLOR_KEYS;
  categories: CategoryInterface[];
  cheatSheets: CheatSheetListInterface[];
  toolList: ToolListInterface;
}

export interface CheatSheetState {
  cheatSheets: CheatSheetInterface[];
}
