import {
  PracticalExamplesHub,
  PracticalExamplesState,
  PracticalExampleTeaser
} from "@/interfaces/practical-example";
import { PracticalExamplesHttp } from "../../http/client/practical-examples";
import {
  mapToPracticalExampleTeasers,
  removeListTagsFromTopic
} from "../utils";

const state: PracticalExamplesState = {
  examplesPage: { lists: [] } as PracticalExamplesHub,
  practicalExamples: []
};

const getters = {
  teasersForTags: state => (tagsId): PracticalExampleTeaser[] => {
    const teasers = mapToPracticalExampleTeasers(state.practicalExamples);
    return teasers.filter(({ tags }) =>
      tags.some(tag => tagsId.includes(tag.id))
    );
  },
  currentPage: (state, getters): PracticalExamplesHub => {
    return {
      ...state.examplesPage,
      lists: state.examplesPage.lists.map(list => {
        const teasers = getters.teasersForTags(list.tags.map(({ id }) => id));
        const groomedTeasers = removeListTagsFromTopic(list.tags, teasers);
        return {
          ...list,
          trainings: groomedTeasers
        };
      })
    };
  },
  content: state => trainingId => {
    return state.practicalExamples.find(({ id }) => id === trainingId);
  }
};

const actions = {
  fetchPracticalExamplesPage: async ({ commit }) => {
    const page = await PracticalExamplesHttp.getPracticalExamplesHub();
    const tagsId = page.lists
      .map(({ tags }) => tags.map(({ id }) => id))
      .flat();

    const filters = [
      { path: "field_tags.id", value: [...new Set(tagsId)], operator: "IN" }
    ];
    const examples = await PracticalExamplesHttp.getPracticalExamples(filters);
    commit("setExamplesPage", [page, examples]);
  },
  fetchTrainingsForLearningTopic: async ({ commit }, topicId) => {
    const filters = [{ path: "field_learning_topics.id", value: topicId }];
    const examples = await PracticalExamplesHttp.getPracticalExamples(filters);
    commit("setPracticalExamples", examples);
  }
};

const mutations = {
  setExamplesPage(state, examplePage) {
    const [page, examples] = examplePage;
    state.examplesPage = page;
    state.practicalExamples = examples;
  },
  setPracticalExamples(state, examples) {
    state.practicalExamples = examples;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
};
