




import { defineComponent } from "@vue/composition-api";
import FcFormChangePassword from "@/components/FcForm/FcFormChangePassword.vue";

export default defineComponent({
  components: {
    FcFormChangePassword
  }
});
