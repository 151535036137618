





























































import { defineComponent } from "@vue/composition-api";
import UiInput from "@/components/ui/UiInput.vue";
import UiButton from "@dpa-id-components/ui-button";
import FcPublicHeader from "./lib/FcPublicHeader.vue";
import FcPublicFooter from "./lib/FcPublicFooter.vue";
import FcPublicCard from "./lib/FcPublicCard.vue";
import store from "@/store";
import { Routes } from "@/router/types";

export default defineComponent({
  components: {
    UiButton,
    UiInput,
    FcPublicHeader,
    FcPublicFooter,
    FcPublicCard
  },

  data() {
    return {
      email: "",
      password: "",
      errorMessage: {
        general: "",
        email: "",
        password: ""
      }
    };
  },

  methods: {
    handleInput(key) {
      if (this.errorMessage[key]) {
        this.$set(this.errorMessage, key, "");
      }
    },

    validate() {
      this.errorMessage = {
        general: "",
        email: "",
        password: ""
      };

      let isValid = true;

      // regex email
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

      if (!this.email || !emailRegex.test(this.email)) {
        this.$set(this.errorMessage, "email", this.$t("auth.invalidEmail"));
        isValid = false;
      }

      if (!this.password) {
        this.$set(
          this.errorMessage,
          "password",
          this.$t("auth.invalidPassword")
        );
        isValid = false;
      }

      return isValid;
    },

    submit() {
      if (!this.validate()) return;

      store
        .dispatch("auth/login", {
          email: this.email,
          password: this.password
        })
        .then(() => {
          this.$router.push({
            name: Routes.LearningTopicHubPage
          });
        })
        .catch(() => {
          this.$set(
            this.errorMessage,
            "general",
            this.$t("auth.invalidCredentials")
          );
        });
    }
  }
});
