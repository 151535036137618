










import { defineComponent } from "@/composition-api";

export default defineComponent({
  props: {
    to: {
      type: [Object, null],
      required: false,
      default: null
    }
  },
  computed: {
    tagName(): string {
      if (this.to !== null) {
        return "router-link";
      }
      return "div";
    }
  }
});
