




























import { defineComponent, computed } from "@/composition-api";
import UiCard from "@/components/ui/UiCard.vue";
import MobileContextButtons from "@/components/FcContextButtonsMobile/FcContextButtonsMobile.vue";
import useBreakpoints from "@/composition/useBreakpoints";
import { PropType } from "vue";
import { Route } from "vue-router";

export default defineComponent({
  components: {
    UiCard,
    MobileContextButtons
  },
  props: {
    title: {
      type: String,
      required: true
    },
    illustrationUrl: {
      type: String,
      required: true
    },
    primaryColor: {
      type: String,
      default: "black"
    },
    printRoute: {
      type: Object as PropType<Route> | null,
      default: null
    }
  },
  setup(props) {
    const headerStyles = computed(() => {
      return {
        "background-image": props.illustrationUrl
          ? `url(${props.illustrationUrl})`
          : null
      };
    });

    const { isDesktop } = useBreakpoints();

    return { headerStyles, isDesktop };
  }
});
