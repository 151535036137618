<template>
  <transition
    enter-class="opacity-0"
    enter-to-class="opacity-100"
    enter-active-class="transition-opacity duration-200 ease-linear"
    leave-class="opacity-100"
    leave-active-class="transition-opacity duration-200 ease-linear"
    leave-to-class="opacity-0"
  >
    <slot></slot>
  </transition>
</template>

<script>
export default {};
</script>
