import { KJUR } from "jsrsasign";

/**
 * Validates a JWT token from the Dpa-ID servers and stores it in local storage
 * if successful.
 *
 * @param {String} token a JWT token from the dpa ID CAS server
 * @param {String} pubKey a public key used for decoding the JWT token
 * @returns {Object} the user information stored in the CAS system (dpa-ID)
 */

export const validateToken = (jwtToken: string, pubKey: string) => {
  const verified = KJUR.jws.JWS.verifyJWT(jwtToken, pubKey, {
    alg: ["RS512"]
  });

  if (verified) {
    return KJUR.jws.JWS.parse(jwtToken).payloadObj;
  }

  return null;
};

export default {
  validateToken
};
