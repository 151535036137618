


















import { defineComponent, computed, ref } from "@/composition-api";
import FcCategoryTabs from "@/components/FcCategoryTabs/FcCategoryTabs.vue";
import FcTrainingTeaserList from "@/components/FcTrainingTeaserList/FcTrainingTeaserList.vue";
import FcInteractiveContent from "@/components/FcInteractiveContent/FcInteractiveContent.vue";

export default defineComponent({
  props: {
    topicId: {
      type: String,
      required: true
    },
    trainingId: {
      type: String,
      required: true
    }
  },
  components: {
    "interactive-content": FcInteractiveContent,
    "category-tabs": FcCategoryTabs,
    "training-teaser-list": FcTrainingTeaserList
  },
  watch: {
    topicId(value) {
      this.getNewPage(value);
    }
  },
  setup(props, { root }) {
    const isLoading = ref(true);

    const getNewPage = pageId => {
      isLoading.value = true;
      root.$store
        .dispatch("learningTopic/fetchLearningTopicDetailPage", pageId)
        .finally(() => {
          isLoading.value = false;
        });
      root.$store.dispatch("training/getVisistedTrainingStatus");
    };
    getNewPage(props.topicId);

    const page = computed(() => {
      const learningTopicTrainingPage = root.$store.getters[
        "learningTopic/learningTopicTrainingsPage"
      ](props.topicId);

      if (isLoading.value) {
        return {
          ...learningTopicTrainingPage,
          teaserList: {
            scrollable: true,
            trainings: Array(6)
              .fill({})
              .map(() => ({
                id: String(Math.floor(Math.random() * 999))
              }))
          }
        };
      }

      return learningTopicTrainingPage;
    });

    const content = computed(() =>
      root.$store.getters["practicalExample/content"](props.trainingId)
    );

    const visitedTrainingIds = computed(
      () => root.$store.state.training.visitedTrainingIds
    );

    return {
      getNewPage,
      page,
      visitedTrainingIds,
      content,
      isLoading
    };
  }
});
