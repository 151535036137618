var render = function (_h,_vm) {var _c=_vm._c;return _c('div',{staticClass:"grid grid-cols-1 gap-4",class:[
    _vm.data.class,
    _vm.data.staticClass,
    {
      'sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 print:grid-cols-3': !(
        Boolean(_vm.data.staticClass && _vm.data.staticClass.includes('grid')) ||
        Boolean(_vm.data.class && _vm.data.class.join('').includes('grid'))
      )
    }
  ]},[_vm._l((_vm.props.items),function(item){return _vm._t("default",null,{"item":item})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }