


























import Vue, { PropType } from "vue";
import { ContentType, TagInterface, TAILWIND_COLOR_KEYS } from "@/interfaces";
import FcTag from "@/components/FcTag/FcTag.vue";
import UiCard from "@/components/ui/UiCard.vue";
import { Routes } from "@/router/types";
import { Location } from "vue-router";

export default Vue.extend({
  components: {
    UiCard,
    FcTag
  },
  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    illustrationUrl: {
      type: String,
      required: true
    },
    primaryColor: {
      type: String as PropType<TAILWIND_COLOR_KEYS>,
      default: "gray-900"
    },
    shortDescription: {
      type: String,
      required: true
    },
    tags: {
      type: Array as PropType<TagInterface[]>,
      default: () => []
    },
    type: {
      type: String as PropType<ContentType>,
      default: ContentType.LearningTopic
    }
  },
  computed: {
    headerStyles(): object {
      return {
        "background-image": this.illustrationUrl
          ? `url(${this.illustrationUrl})`
          : null
      };
    },
    route(): Location {
      return {
        name:
          this.type === ContentType.Toolkit
            ? Routes.ToolDetail
            : Routes.LearningTopicDetails,
        params: {
          id: this.id
        }
      };
    }
  }
});
