import { TrainingInterface } from "@/interfaces";
import { getIncludedObjectAttributes, getChapterSeconds } from "./utils";

export const TrainingResponseTranslator = (response): TrainingInterface[] => {
  const { included, data } = response;

  if (!data) return [];

  const learningTopics = data.map(topic => {
    const attr = topic.attributes;
    const {
      field_chapters: fieldChapters,
      field_learning_topics: fieldLearningTopics,
      field_thumbnail: fieldThumbnail,
      field_tags: fieldTags
    } = topic.relationships;

    const thumbnailUrl = getIncludedObjectAttributes(
      included,
      fieldThumbnail.data.id
    )?.uri.url;

    const tags = fieldTags.data
      .map(tag => {
        const tagAttr = getIncludedObjectAttributes(included, tag.id);
        if (!tagAttr) return null;
        return {
          id: tag.id,
          label: tagAttr?.name
        };
      })
      .filter(Boolean);

    const chapters = fieldChapters.data.map(chapter => {
      const chapterAttr = getIncludedObjectAttributes(included, chapter.id);
      return {
        id: chapter.id,
        label: chapterAttr.field_title,
        chapter: getChapterSeconds(
          chapterAttr.field_hours,
          chapterAttr.field_minutes,
          chapterAttr.field_seconds
        )
      };
    });

    const learningTopics = fieldLearningTopics.data.map(topic => {
      const attr = getIncludedObjectAttributes(included, topic.id);
      return {
        id: topic.id,
        title: attr.title
      };
    });

    return {
      id: topic.id,
      thumbnailUrl,
      tags,
      title: attr.name,
      video: {
        src: thumbnailUrl,
        videoId: attr.field_video_id,
        title: attr.name,
        chapters
      },
      learningTopics: learningTopics,
      created: attr.created,
      changed: attr.changed
    };
  });

  return learningTopics;
};
