import { ApiEndpoint } from "../endpoint";
import { TrainingResponseTranslator } from "../translators/training";
import { DrupalJsonApiParams } from "drupal-jsonapi-params";
import useApi from "@/composition/useApi";
import { sortByChangedDate } from "../translators/utils";

export class TrainingHttp {
  static async getTrainingVideos(filters = []) {
    const { client } = useApi();
    const apiParams = new DrupalJsonApiParams();
    apiParams.addInclude([
      "field_chapters",
      "field_thumbnail",
      "field_tags",
      "training_type",
      "field_learning_topics"
    ]);
    if (filters.length) {
      filters.forEach(filter => {
        apiParams.addFilter(filter.path, filter.value, filter.operator);
      });
    }
    const queryString = apiParams.getQueryString({ encode: false });

    const data = await client.get(
      `${ApiEndpoint.TrainingVideo}?${queryString}`
    );

    const trainings = TrainingResponseTranslator(data);
    return trainings.sort(sortByChangedDate);
  }

  static async postVisitedStatus(trainingId: string) {
    const payload = {
      data: {
        type: "activity--activity",
        attributes: {
          // eslint-disable-next-line
          content_uuid: trainingId,
          // eslint-disable-next-line
          activity_type: "visited"
        }
      }
    };

    const { client } = useApi();
    const token = await client.get(`${ApiEndpoint.SessionToken}?_format=json`);
    const data = await client.post(`${ApiEndpoint.VisitedTraining}`, payload, {
      headers: {
        "X-CSRF-Token": token,
        Accept: "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json"
      }
    });

    return data;
  }

  static async getVisitedStatus() {
    const { client } = useApi();
    const contentIds = [];
    try {
      const getData = async (nextURL = null) => {
        const { data, links } = await client.get(
          `${nextURL || ApiEndpoint.VisitedTraining}`
        );
        contentIds.push(
          ...data.map(({ attributes }) => attributes.content_uuid)
        );
        if ("next" in links) {
          await getData(links.next.href);
        }
      };

      await getData();
      return contentIds;
    } catch (e) {
      return [];
    }
  }
}
