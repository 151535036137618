import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import navigation from "./modules/navigation";
import learningTopic from "./modules/learning-topic";
import training from "./modules/training";
import legal from "./modules/legal";
import tool from "./modules/tool";
import { stageConfig } from "./modules/stageConfig";
import practicalExample from "./modules/practical-examples";
import tag from "./modules/tag";
import { RootState } from "./types";
import { auth } from "./modules/auth";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

const store: StoreOptions<RootState> = {
  modules: {
    stageConfig,
    auth,
    navigation,
    learningTopic,
    training,
    legal,
    tool,
    tag,
    practicalExample
  },
  strict: debug
};

export default new Vuex.Store<RootState>(store);
