


















import Vue, { PropType } from "vue";
import UiTabs from "../ui/UiTabs.vue";
import { CategoryInterface } from "../../interfaces";

export default Vue.extend({
  components: { UiTabs },
  props: {
    categories: {
      type: Array as PropType<CategoryInterface[]>,
      default: () => []
    }
  }
});
