
















import Vue from "vue";
export default Vue.extend({
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    backgroundColor() {
      return this.active ? "#0045F4" : "#E3E3E3";
    },
    color() {
      return this.active ? "#FFF" : "#757575";
    }
  }
});
