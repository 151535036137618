











import { defineComponent, computed } from "@/composition-api";
import FcRichText from "@/components/FcRichText/FcRichText.vue";

export default defineComponent({
  components: { FcRichText },
  props: {
    node: {
      type: String,
      required: true
    }
  },
  setup(props, { root }) {
    const pageContent = computed(
      (): string | undefined => root.$store.state.legal.pages[props.node]
    );

    return {
      pageContent
    };
  }
});
