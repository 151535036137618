import { ActionTree } from "vuex";
import { validateToken } from "./lib/tokenValidation";
import { AuthState } from "./types";
import { RootState } from "../../types";
import axios from "axios";
import useApi from "@/composition/useApi";
import { ApiEndpoint } from "@/http/endpoint";
import { Drupal, DrupalAuth } from "drupal-js-sdk";

export const actions: ActionTree<AuthState, RootState> = {
  async authenticate(
    { commit },
    {
      token,
      pubKey,
      mutation = "setDpaJWT"
    }: { token: string; pubKey: string; mutation: string }
  ) {
    try {
      validateToken(token, pubKey);
      commit(mutation, token);

      return true;
    } catch (e) {
      commit(mutation, "");
      commit("setStatus", "error");

      return false;
    }
  },

  async authorize({ commit, dispatch }, { dpaJWT }: { dpaJWT: string }) {
    try {
      const { data } = await axios.get(`/api/dpaid/login?ticket=${dpaJWT}`, {
        auth: {
          username: "dpa",
          password: "dpa"
        }
      });
      commit("setDrupalJWT", data.token);
      dispatch("navigation/getNavigationItems", null, { root: true });
      return true;
    } catch (e) {
      commit("setDrupalJWT", "");
      commit("setStatus", "error");
      return false;
    }
  },

  async login({ dispatch, rootState }, { email, password }) {
    const api = new Drupal({
      baseURL: rootState.stageConfig.apiBaseUrl
    });

    const drupalAuth = new DrupalAuth(api);
    return drupalAuth.login(email, password).then(response => {
      dispatch("setTokens", {
        drupalJWT: response.data.access_token
      });
    });
  },

  async logout({ commit }) {
    const { client } = useApi();
    const token = await client.get(`${ApiEndpoint.SessionToken}?_format=json`);

    // Log out drupal_jwt.
    await client.post(
      `/dpaid/logout`,
      {},
      {
        withCredentials: true,
        headers: {
          "X-CSRF-Token": token,
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json"
        }
      }
    );

    // Log out from cookies.
    await client.get("/user/logout");

    commit("setDpaJWT", "");
    commit("setDrupalJWT", "");
  },

  async resetPassword(
    { commit, dispatch },
    { name, temporaryPassword, newPassword }
  ) {
    const { client } = useApi();
    const token = await client.get(`${ApiEndpoint.SessionToken}?_format=json`);

    return client
      .post(
        `/user/lost-password-reset?_format=json`,
        {
          name,
          // eslint-disable-next-line
          temp_pass: temporaryPassword,
          // eslint-disable-next-line
          new_pass: newPassword
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRF-Token": token,
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        }
      )
      .then(() => {
        commit("setDpaJWT", "");
        commit("setDrupalJWT", "");
        return dispatch("login", { email: name, password: newPassword });
      });
  },

  async forgotPassword(_, { email }) {
    const { client } = useApi();
    const token = await client.get(`${ApiEndpoint.SessionToken}?_format=json`);

    return client.post(
      `/user/lost-password?_format=json`,
      {
        mail: email
      },
      {
        withCredentials: true,
        headers: {
          "X-CSRF-Token": token,
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    );
  },

  resetTokens({ commit }) {
    commit("setDpaJWT", "");
    commit("setDrupalJWT", "");
  },

  setTokens({ commit }, { dpaJWT, drupalJWT }) {
    if (dpaJWT) {
      commit("setDpaJWT", dpaJWT);
    }
    if (drupalJWT) {
      commit("setDrupalJWT", drupalJWT);
    }
  }
};
