const { boxShadow, fontSize } = require("tailwindcss/defaultTheme");
const flattenColorPalette = require("tailwindcss/lib/util/flattenColorPalette")
  .default;

module.exports = {
  purge: {
    enabled: process.env === "production" ? true : false,
    mode: "layers",
    content: ["./public/**/*.html", "./src/**/*.vue"],
    options: {
      whitelist: [
        /-(leave|enter|appear)(|-(to|from|active))$/,
        /^(?!(|.*?:)cursor-move).+-move$/,
        /^router-link(|-exact)-active$/,
        /^bg-/
      ]
    }
  },
  theme: {
    inset: {
      "0": 0,
      "1/2": "50%",
      "15": "3.75rem",
      full: "100%"
    },
    screens: {
      sm: "640px",
      md: "768px",
      lg: "1024px",
      xl: "1280px",
      xxl: "1600px"
    },
    fontSize: {
      "2xs": ["0.5rem", "0.75rem"],
      ...fontSize
    },
    boxShadow: {
      ...boxShadow,
      md: "0 2px 4px 0 rgba(0, 0, 0, 0.1)"
    },
    groupLevel: 2,
    groupScope: "scope",
    groupVariants: ["hover", "focus"],
    extend: {
      fontFamily: {
        sans: ["Inter", "sans-serif"],
        marketing: ["Futura", "sans-serif"]
      },
      spacing: {
        // inherit: "inherit",
        half: "0.125rem", // 2px
        "14": "3.5rem" //  "56px", text input height
      },
      duration: {
        "0": "0ms"
      },
      colors: {
        transparent: "rgba(255, 255, 255, 0)",
        black: "#000",
        white: "#fff",
        // TODO: Limit to Lennarts Scale
        gray: {
          "100": "#F5F5F5",
          "200": "#EEEEEE",
          "300": "#E3E3E3",
          "400": "#BABABA",
          "500": "#8C8C8C",
          "600": "#7E7E7E",
          "700": "#757575",
          "800": "#4D4D4D",
          "900": "#212121"
        },
        blue: {
          default: "#003EDB",
          dark: "#0019A5"
        },
        red: {
          default: "#E53935",
          dark: "#AB000D"
        },
        pink: {
          default: "#F5138D",
          dark: "#BD0060"
        },
        purple: {
          default: "#8E24AA",
          dark: "#5C007A"
        },
        cyan: {
          default: "#00ACC1",
          dark: "#007C91"
        },
        teal: {
          default: "#00897B",
          dark: "#005B4F"
        },
        green: {
          default: "#30DF7B",
          dark: "#00AC4D"
        },
        yellow: {
          default: "#F9F500",
          dark: "#C1BE00"
        },
        orange: {
          default: "#FFB300",
          dark: "#C68400"
        },
        "alert-red": {
          default: "#F53000",
          dark: "#BA2400"
        },
        "alert-green": {
          default: "#00D200",
          dark: "#00AA00"
        }
      },
      opacity: {
        "60": "0.6",
        "20": "0.2"
      },
      boxShadow: {
        default: "0px 1px 1px rgba(0, 0, 0, 0.1)",
        negative: "0px -1px 1px rgba(0, 0, 0, 0.1)",
        md: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        lg: "0px 4px 6px rgba(0, 0, 0, 0.25)"
      },
      inset: {
        0: 0,
        auto: "auto",
        1: "0.25rem",
        3: "0.75rem",
        4: "1rem",
        8: "2rem",
        16: "4rem",
        19: "4.75rem"
      },
      screens: {
        print: { raw: "print" }
      },
      typography: theme => ({
        default: {
          css: {
            color: theme("colors.gray.900"),
            strong: {
              color: theme("colors.gray.900")
            },
            "ul > li::before": {
              backgroundColor: theme("colors.gray.500")
            }
          }
        }
      })
    }
  },
  variants: {
    scrollbar: ["rounded"],
    borderWidth: ["hover"],
    zIndex: ["hover"],
    opacity: ["group-focus", "group-hover"],
    outline: ["active"]
  },
  plugins: [
    require("@tailwindcss/typography"),
    require("@tailwindcss/aspect-ratio"),
    require("tailwindcss-scroll-snap"),
    require("tailwind-scrollbar"),
    require("tailwindcss-nested-groups"),
    ({ addUtilities, theme, variants }) => {
      const colors = flattenColorPalette(theme("borderColor"));
      delete colors["default"];

      const colorMap = Object.keys(colors).map(color => ({
        [`.border-t-${color}`]: { borderTopColor: colors[color] },
        [`.border-r-${color}`]: { borderRightColor: colors[color] },
        [`.border-b-${color}`]: { borderBottomColor: colors[color] },
        [`.border-l-${color}`]: { borderLeftColor: colors[color] }
      }));
      const utilities = Object.assign({}, ...colorMap);

      addUtilities(utilities, variants("borderColor"));
    }
  ]
};
