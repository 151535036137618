














import { defineComponent, computed } from "@/composition-api";
import useDpaSSO from "@/composition/useDpaSSO";
import { Routes } from "@/router/types";
import store from "@/store";
import DpaIdUser from "@/vendor/dpa-id-user";
import UiButton from "@/components/ui/UiButton.vue";
import LogoutIcon from "@/assets/vectors/icons/upload.svg?inline";

export default defineComponent({
  components: {
    DpaIdUser,
    UiButton,
    LogoutIcon
  },
  methods: {
    async handleLogout() {
      await store.dispatch("auth/logout");

      if (this.useDpaId) {
        window.location.assign(this.logoutURL);
      } else {
        this.$router.push({
          name: Routes.Login
        });
      }
    }
  },
  setup() {
    const { useDpaId } = useDpaSSO();

    const dpaJWT = computed(() => store.state.auth.dpaJWT);

    const ssoURL = computed(() => store.state.stageConfig.ssoURL);

    const logoutURL = computed(
      () => `${ssoURL.value}/logout?service=${window.location.origin}`
    );

    const profileUrl = computed(() => store.state.stageConfig.profileUrl);

    return {
      useDpaId,
      logoutURL,
      profileUrl,
      ssoURL,
      dpaJWT
    };
  }
});
