import { TagInterface } from "@/interfaces";

export const TagListResponseTranslator = (response): TagInterface[] => {
  const { data } = response;

  return data.map(tagData => {
    const tag: TagInterface = {
      id: tagData.id,
      label: tagData.attributes.name
    };

    return tag;
  });
};
