import {
  CategoryInterface,
  LearningTopicInterface,
  TrainingInterface,
  TrainingTeaserInterface,
  PracticalExample,
  PracticalExampleTeaser,
  LearningTopicTeaserInterface,
  TagTypeEnum,
  ContentType
} from "@/interfaces";
import {
  ToolHubPage,
  ToolkitPage,
  ToolkitTeaserInterface
} from "@/interfaces/tool";
import { Routes } from "@/router/types";

export const getCategories = (
  topic: LearningTopicInterface,
  trainings: TrainingInterface[]
): CategoryInterface[] => {
  const categories = [];

  categories.push({
    to: {
      name: Routes.LearningTopicDetails,
      params: {
        id: topic.id
      }
    },
    label: "Übersicht"
  });

  if (trainings.length) {
    categories.push({
      to: {
        name: Routes.LearningTopicTrainings,
        params: {
          id: topic.id,
          trainingId: trainings[0].id
        }
      },
      label: "Training"
    });
  }

  if (topic.cheatSheets.length) {
    categories.push({
      to: {
        name: Routes.LearningTopicTools,
        params: {
          id: topic.id
        }
      },
      label: "Tools & Zusammenfassung"
    });
  }

  return categories;
};

export const mapToTrainingTeasers = (
  topic: LearningTopicInterface,
  trainings: TrainingInterface[],
  withLearningTopics = false
): TrainingTeaserInterface[] => {
  return trainings.map(training => {
    const tags = [];
    if (withLearningTopics) {
      tags.push(
        ...training.learningTopics.map(({ id, title: label }) => ({
          id,
          label
        }))
      );
    }
    tags.push(...training.tags);

    return {
      id: training.id,
      src: training.thumbnailUrl,
      tags: tags,
      title: training.title,
      to: {
        name: Routes.LearningTopicTrainings,
        params: {
          id: topic.id,
          trainingId: training.id
        }
      }
    };
  });
};

export const mapToToolkitTeasers = (
  toolkits: ToolkitPage[]
): ToolkitTeaserInterface[] => {
  return toolkits.map(toolkit => ({
    id: toolkit.id,
    title: toolkit.title,
    illustrationUrl: toolkit.teaserIllustrationUrl,
    shortDescription: toolkit.shortDescription
  }));
};

export const mapFromToolkitToLearningTopicTeasers = (
  toolkits: ToolHubPage[]
): LearningTopicTeaserInterface[] => {
  return toolkits.map(toolkit => ({
    id: toolkit.id,
    title: toolkit.title,
    illustrationUrl: toolkit.teaserIllustrationUrl,
    primaryColor: toolkit.primaryColor,
    shortDescription: toolkit.shortDescription,
    tags: toolkit.tags,
    type: ContentType.Toolkit
  }));
};

export const mapToPracticalExampleTeasers = (
  content: PracticalExample[]
): PracticalExampleTeaser[] => {
  return content.map(training => {
    return {
      id: training.id,
      src: training.thumbnailUrl,
      tags: [
        ...training.learningTopics.map(({ id, title: label }) => ({
          id,
          label,
          type: TagTypeEnum.LearningTopic
        })),
        ...training.tags
      ],
      title: training.title,
      to: {
        name: Routes.PracticalExamples,
        query: {
          training: training.id
        }
      }
    };
  });
};

export const removeListTagsFromTopic = (listTags, topics) => {
  const listTagsIds = listTags.map(({ id }) => id);
  const changedTopics = [...topics];
  return changedTopics.map(topic => {
    return {
      ...topic,
      tags: topic.tags.filter(tag => !listTagsIds.includes(tag.id))
    };
  });
};

const mapToSummaryItem = (item, type) => {
  let route = Routes.ToolDetail;
  let printRoute = Routes.PrintToolDetail;
  if (type === "learning-topic") {
    route = Routes.LearningTopicTools;
    printRoute = Routes.PrintLearningTopicTools;
  }

  return {
    id: item.id,
    title: item.title,
    thumbnailUrl: item.thumbnailUrl,
    route: {
      name: route,
      params: {
        id: item.id
      }
    },
    printRoute: {
      name: printRoute,
      params: {
        id: item.id
      }
    }
  };
};

export const fromToolkitToSummary = toolkit => {
  return mapToSummaryItem(toolkit, "toolkit");
};

export const fromLearningTopicToSummary = topic => {
  return mapToSummaryItem(topic, "learning-topic");
};

/**
 * Static toolkit teaser for the summary page
 */
export const summaryPage: ToolHubPage = {
  id: "summary",
  title: "Zusammenfassungen",
  shortDescription:
    "In dieser Liste findest Du kompakte Zusammenfassungen zu jedem Thema zum Nachschlagen oder Herunterladen.",
  description:
    "<p>In dieser Liste findest Du kompakte Zusammenfassungen zu jedem Thema zum Nachschlagen oder Herunterladen.</p>",
  teaserIllustrationUrl: "/images/summary-teaser.svg",
  primaryColor: "green",
  tags: []
};
