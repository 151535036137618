




import Vue from "vue";

export default Vue.extend({
  props: {
    embedCode: {
      type: String,
      required: true
    }
  },
  computed: {
    baseUrl(): string {
      return window.location.origin;
    },
    dataPathCss(): string {
      return `${this.baseUrl}/css/embed-`; // ends automatically with style.css
    },
    applicationSrc(): string {
      const match = this.embedCode.match(/(<script.*?<\/script>)/);
      const script = match ? match[0] : null;
      return script;
    },
    embedApp(): string {
      // matches the "div" which contains the application data
      const match = this.embedCode.match(
        /(<(?:div|iframe).*?<\/(?:div|iframe)>)/
      );
      const div = match ? match[0] : null;

      if (!div) return "";

      // injects custom css into the application
      return div.replace("<div", `<div data-path-css="${this.dataPathCss}"`);
    }
  },
  created() {
    if (!this.applicationSrc) return;
    const div = document.createElement("div");
    div.innerHTML = this.applicationSrc;
    const appScript = document.createElement("script");
    appScript.setAttribute("src", div.firstElementChild.getAttribute("src"));
    document.head.appendChild(appScript);
  }
});
