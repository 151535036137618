

















import Vue, { PropType } from "vue";
import { Location } from "vue-router";
import ExternalLink from "../FcExternalLink/FcExternalLink.vue";
import DownloadIcon from "@/assets/vectors/icons/download.svg?inline";

export default Vue.extend({
  components: {
    DownloadIcon,
    ExternalLink
  },
  props: {
    printRoute: {
      type: Object as PropType<Location>,
      required: true
    }
  },
  computed: {
    mailTo(): string {
      const email = this.$t("reportEmail.email") as string;
      const subject = this.$t("reportEmail.subject") as string;
      const body = (this.$t("reportEmail.body") as string).replace(
        "{URL}",
        encodeURIComponent(window.location.href)
      );
      return `mailto:${email}?subject=${subject}&body=${body}`;
    }
  }
});
