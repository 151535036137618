

















































































































































































































































































































































































































































































































































import { defineComponent } from "@/composition-api";
import FcRichText from "@/components/FcRichText/FcRichText.vue";

export default defineComponent({
  components: { FcRichText }
});
