








































import Vue, { PropType } from "vue";
import NavItem from "@/components/nav/NavItem.vue";
import OpenBook from "@/assets/vectors/icons/fc-open-book.svg?inline";
import BulbOutline from "@/assets/vectors/icons/bulb-outline.svg?inline";
import BriefcaseOutline from "@/assets/vectors/icons/briefcase-outline.svg?inline";
import Slack from "@/assets/vectors/icons/fc-slack.svg?inline";
import { Route } from "vue-router";

export default Vue.extend({
  components: {
    NavItem,
    OpenBook,
    BulbOutline,
    BriefcaseOutline,
    Slack
  },
  props: {
    label: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    children: {
      type: Array,
      default: () => []
    },
    to: {
      type: Object as PropType<Route>,
      default: () => ({})
    },
    external: {
      type: Boolean,
      default: false
    },
    href: {
      type: String,
      default: ""
    }
  },
  computed: {
    isAnyChildrenActive(): boolean {
      if (!this.children.length) return false;

      const nameParts = this.$route?.name?.split("__");

      if (nameParts === undefined) return false;

      const [prefix] = nameParts;
      const childrenRoutes = this.children.map(({ to }) =>
        to.name.startsWith(prefix)
      );
      return childrenRoutes.filter(Boolean).length > 0;
    }
  }
});
