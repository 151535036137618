


















import Vue, { PropType } from "vue";
import UiGrid from "@/components/ui/UiGrid.vue";
import UiSlider from "@/components/ui/UiSlider.vue";
import FcSectionHeader from "@/components/FcSectionHeader/FcSectionHeader.vue";
import FcTrainingTeaser from "@/components/FcTrainingTeaser/FcTrainingTeaser.vue";
import FcTrainingTeaserSkeleton from "@/components/FcTrainingTeaser/FcTrainingTeaserSkeleton.vue";
import { Identifiable, TrainingTeaserInterface } from "@/interfaces";

export default Vue.extend({
  components: {
    "section-header": FcSectionHeader,
    FcTrainingTeaser,
    FcTrainingTeaserSkeleton,
    UiGrid,
    UiSlider
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    trainings: {
      type: Array as PropType<TrainingTeaserInterface[] | Identifiable[]>,
      default: () => []
    },
    scrollable: {
      type: Boolean,
      default: false
    },
    visitedTrainingIds: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    trainingsWithSelected(): TrainingTeaserInterface[] | Identifiable[] {
      if (this.loading) return this.trainings;

      return this.trainings.map(teaser => {
        return {
          ...teaser,
          selected: this.$route.query.training === teaser.id
        };
      });
    },
    component(): string {
      const baseComponent = "fc-training-teaser";
      return this.loading ? `${baseComponent}-skeleton` : baseComponent;
    }
  }
});
