













import Vue from "vue";
import FcRichText from "@/components/FcRichText/FcRichText.vue";

export default Vue.extend({
  components: {
    FcRichText
  },
  props: {
    content: {
      type: Array,
      default: () => []
    }
  }
});
