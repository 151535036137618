import { TagHttp } from "@/http/client/tag";
import {
  LearningTopicInterface,
  PracticalExample,
  TagInterface,
  TagState,
  ToolInterface
} from "@/interfaces";
import { mapToPracticalExampleTeasers } from "../utils";

const state: TagState = {
  tags: [],
  learningTopics: [],
  trainings: [],
  tools: []
};

const actions = {
  async fetchAvailableTags({ commit }) {
    const tags = await TagHttp.getAvailableTags();
    commit("setTags", tags);
  },
  getLearningTopicsByTag: async ({ commit }, tagId) => {
    const learningTopics = await TagHttp.getLearningTopicsByTag(tagId);
    commit("learningTopics", learningTopics);
  },
  getTrainingsByTag: async ({ commit }, tagId) => {
    const trainings = await TagHttp.getTrainingsByTag(tagId);
    commit("setTrainings", trainings);
  },
  getToolListsByTag: async ({ commit }, tagId) => {
    const tools = await TagHttp.getToolListsByTag(tagId);
    commit("setTools", tools);
  }
};

const mutations = {
  setTags(state: TagState, payload: TagInterface[]) {
    state.tags = payload;
  },
  learningTopics(state: TagState, payload: LearningTopicInterface[]) {
    state.learningTopics = payload;
  },
  setTrainings(state: TagState, payload: PracticalExample[]) {
    state.trainings = mapToPracticalExampleTeasers(payload);
  },
  setTools(state: TagState, payload: ToolInterface[]) {
    state.tools = payload;
  }
};

export default {
  state,
  actions,
  mutations,
  namespaced: true
};
