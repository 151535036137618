import { MutationTree } from "vuex";
import { StageConfigState } from "./types";

export const mutations: MutationTree<StageConfigState> = {
  setConfig: (state, { data }: { data: StageConfigState }) => {
    state.ssoURL = data.ssoURL;
    state.stage = data.stage;
    state.pubKey = data.pubKey;
    state.profileUrl = data.profileUrl;
    state.apiBaseUrl = data.apiBaseUrl;
    state.baseUrl = data.baseUrl;
    state.matomoHost = data.matomoHost;
    state.matomoSiteId = data.matomoSiteId;
  }
};
