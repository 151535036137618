import Vue from "vue";
import { Routes } from "@/router/types";
import { NavigationHttp } from "@/http/client/navigation.http";
import { NavigationState } from "@/interfaces";

const state: NavigationState = {
  isLeftNavOpen: true,
  items: [
    {
      id: "LEARNING_TOPICS",
      label: "Themenübersicht",
      icon: "OpenBook",
      to: {
        name: Routes.LearningTopicHubPage
      },
      children: []
    },
    {
      id: "TRAININGS",
      label: "Praxis-Beispiele",
      icon: "BulbOutline",
      to: {
        name: Routes.PracticalExamples
      }
    },
    {
      id: "CHEAT_SHEETS",
      label: "Werkzeugkasten",
      icon: "BriefcaseOutline",
      to: {
        name: Routes.ToolsHub
      },
      children: [
        {
          label: "Zusammenfassungen",
          to: {
            name: Routes.Summary
          }
        }
      ]
    },
    {
      label: "Slack-Community",
      icon: "Slack",
      to: {
        name: Routes.Legal,
        params: {
          node: "slack-community"
        }
      }
    }
  ],
  footerLinks: [
    {
      to: "https://www.dpa.com/faktencheck",
      external: true,
      label: "Über uns"
    },
    {
      to: {
        name: Routes.LegalPrivacyPolicy,
        params: {
          node: "datenschutz"
        }
      },
      label: "Datenschutz"
    },
    {
      to: {
        name: Routes.Legal,
        params: {
          node: "impressum"
        }
      },
      label: "Impressum"
    },
    {
      to: {
        name: Routes.LegalTermsOfService,
        params: {
          node: "agb"
        }
      },
      label: "AGB"
    }
  ]
};

const actions = {
  getNavigationItems: async ({ commit }) => {
    const items = await NavigationHttp.getAllItems();
    items.forEach(item => commit("setNavigationChildren", item));
  }
};

const mutations = {
  setIsLeftNavOpen: (state, isLeftNavOpen) => {
    state.isLeftNavOpen = isLeftNavOpen;
  },
  setNavigationChildren: (state, { id, route, children }) => {
    const index = state.items.findIndex(item => item.id === id);

    const ids = [];
    state.items[index].children.forEach(item => {
      if (item.to?.params?.id) {
        ids.push(item.to.params.id);
      }
    });

    const navigation = children
      .map(nav => ({
        label: nav.title,
        to: {
          name: route,
          params: {
            id: nav.id
          }
        }
      }))
      .filter(nav => !ids.includes(nav.to.params.id));

    Vue.set(state.items[index], "children", [
      ...navigation,
      ...state.items[index].children
    ]);
  }
};

export default {
  state,
  actions,
  mutations,
  namespaced: true
};
