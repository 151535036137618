import { GetterTree } from "vuex";
import { validateToken } from "./lib/tokenValidation";
import { AuthState } from "./types";
import { RootState } from "../../types";

export const getters: GetterTree<AuthState, RootState> = {
  isAuthenticated: ({ dpaJWT }, getters, rootSate: RootState) => {
    try {
      return !!validateToken(dpaJWT, rootSate.stageConfig.pubKey);
    } catch (e) {
      // console.error(e);
      return false;
    }
  },

  // TODO: validate drupal token against drupal public key
  isAuthorized: ({ drupalJWT }) => {
    return drupalJWT !== "";
  },

  authStatus: ({ status }) => status
};
