


















import { defineComponent, computed, ref } from "@/composition-api";
import FcSectionHeader from "@/components/FcSectionHeader/FcSectionHeader.vue";
import FcLearningTopicTeaserList from "@/components/FcLearningTopicTeaserList/FcLearningTopicTeaserList.vue";

export default defineComponent({
  components: {
    "section-header": FcSectionHeader,
    "teaser-list": FcLearningTopicTeaserList
  },
  setup(props, { root }) {
    const isLoading = ref(true);
    root.$store
      .dispatch("learningTopic/fetchLearningTopicPageHub")
      .finally(() => {
        isLoading.value = false;
      });

    const topicsSections = computed(() => {
      const topicSections =
        root.$store.getters["learningTopic/learningTopicsSections"];

      if (isLoading.value) {
        return topicSections.map(section => ({
          ...section,
          topics: Array(6)
            .fill({})
            .map(() => ({
              id: String(Math.floor(Math.random() * 999))
            }))
        }));
      }

      return topicSections;
    });

    return {
      topicsSections,
      isLoading
    };
  }
});
