


















































import { defineComponent, computed } from "@/composition-api";
import FcLearningTopicHeader from "@/components/FcLearningTopicHeader/FcLearningTopicHeader.vue";
import FcCategoryTabs from "@/components/FcCategoryTabsWrapper/FcCategoryTabsWrapper.vue";
import FcCheatSheetSection from "@/components/FcCheatSheet/FcCheatSheetSection/FcCheatSheetSection.vue";
import FcToolList from "@/components/FcToolList/FcToolList.vue";
import usePrint from "@/composition/usePrint";
import { Routes } from "@/router/types";
import useBreakpoints from "@/composition/useBreakpoints";

export default defineComponent({
  components: {
    "learning-topic-header": FcLearningTopicHeader,
    "category-tabs": FcCategoryTabs,
    "cheat-sheet-section": FcCheatSheetSection,
    "tool-list": FcToolList
  },
  props: {
    topicId: {
      type: String,
      required: true
    },
    print: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { root }) {
    const { isMobile } = useBreakpoints();

    const { topicId, print } = props;
    root.$store.dispatch("learningTopic/fetchLearningTopicDetailPage", topicId);

    const page = computed(() =>
      root.$store.getters["learningTopic/learningTopicCheatSheetPage"](topicId)
    );

    let content = null;
    if (print) {
      const { content: printContent } = usePrint();
      content = printContent;
    }

    const printRoute = {
      name: Routes.PrintLearningTopicTools,
      params: {
        id: topicId
      }
    };

    return {
      page,
      content,
      printRoute,
      isMobile
    };
  }
});
