






import Vue, { PropType } from "vue";
import SummaryItem from "./FcSummaryItem/FcSummaryItem.vue";
import { SummaryItem as SummaryItemInterface } from "@/interfaces";

export default Vue.extend({
  components: {
    SummaryItem
  },
  props: {
    items: {
      type: Array as PropType<SummaryItemInterface[]>,
      default: () => []
    }
  }
});
