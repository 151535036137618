



















import Vue from "vue";
import { Route } from "vue-router";

export default Vue.extend({
  props: {
    active: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 1
    },
    chapter: {
      type: Number,
      default: 0
    }
  },
  computed: {
    route(): Route {
      return {
        ...this.$route,
        query: {
          ...this.$route?.query,
          s: String(this.chapter)
        }
      };
    },
    timeFromSeconds(): Array<number> {
      if (this.chapter === 0) return [0, 0];

      const minutesStr = String(this.chapter / 60);
      const [minutes, secondsStr] = minutesStr.split(".");
      const seconds = secondsStr ? "0." + secondsStr : "0";

      return [parseInt(minutes, 10), Math.round(parseFloat(seconds) * 60)];
    },
    chapterInMinutes(): string {
      const [minutes, seconds] = this.timeFromSeconds;

      const strSeconds = String(seconds).padStart(2, "0");

      return `${minutes}:${strSeconds}`;
    }
  }
});
