































import { defineComponent, ref } from "@/composition-api";
import ChevronDown from "@/assets/vectors/icons/chevron-down.svg?inline";

export default defineComponent({
  components: {
    ChevronDown
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    startOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const isOpen = ref(true);
    isOpen.value = props.startOpen;
    const id = Math.floor(Math.random() * 999);

    return { isOpen, id };
  }
});
