import { MutationTree } from "vuex";
import { AuthState } from "./types";

export const mutations: MutationTree<AuthState> = {
  setDpaJWT(state, token: string) {
    state.dpaJWT = token;
    window.localStorage.setItem("dpa_jwt", token);
  },

  setDrupalJWT(state, token: string) {
    state.drupalJWT = token;
    window.localStorage.setItem("drupal_jwt", token);
  },

  setStatus(state, payload) {
    state.status = payload;
  }
};
