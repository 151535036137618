<template>
  <div>
    <p>Redirecting ...</p>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "Authenticiate"
});
</script>
