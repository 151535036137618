

























































import Vue, { PropType } from "vue";
import { TagInterface } from "@/interfaces";
import FcTag from "@/components/FcTag/FcTag.vue";
import UiCard from "@/components/ui/UiCard.vue";
import FcBlueCheckmark from "@/assets/vectors/icons/fc-blue-checkmark.svg?inline";
import { Route } from "vue-router";

export default Vue.extend({
  components: {
    UiCard,
    FcTag,
    FcBlueCheckmark
  },
  props: {
    selected: {
      type: Boolean,
      default: false
    },
    visited: {
      type: Boolean,
      default: false
    },
    src: {
      type: [String, null],
      required: false,
      default: null
    },
    tags: {
      type: Array as PropType<TagInterface[]>,
      default: () => []
    },
    to: {
      type: [Object, null] as PropType<Route>,
      required: false,
      default: null
    },
    title: {
      type: String,
      required: true
    }
  },
  methods: {
    handleClick() {
      if (!this.visited) {
        this.$store.dispatch(
          "training/sendVisitedTrainingStatus",
          this.to?.params?.trainingId || this.to?.query?.training
        );
      }
    }
  }
});
