









































import {
  defineComponent,
  computed,
  onMounted,
  ref,
  nextTick
} from "@vue/composition-api";
import useBreakpoints from "@/composition/useBreakpoints";
import UiPaginationButton from "@/components/ui/UiPaginationButton.vue";

export default defineComponent({
  components: {
    UiPaginationButton
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const { isMobile, isTablet } = useBreakpoints();

    const numberOfItems = computed(() => props.items.length);

    const itemsPerPage = computed(() => {
      if (isMobile.value) {
        return 1;
      } else if (isTablet.value) {
        return 2;
      }
      return 3;
    });

    const gridContainerStyle = computed(() => {
      let width;

      if (isMobile.value) {
        width = 100 * numberOfItems.value * 0.75;
      } else {
        width = (100 * numberOfItems.value) / itemsPerPage.value;
      }

      return { width: `${width}%` };
    });

    const gridColumnStyle = computed(() => {
      return { width: `${100 / numberOfItems.value}%` };
    });

    const snapItems = ref(null);
    const snapItemWidth = ref(0);
    const updateSnapItemsWidth = () => {
      if (snapItems.value) {
        snapItems.value.forEach(element => {
          element.classList.add("snap-start");
        });
        nextTick(() => {
          const element = Array.from(snapItems.value)[0] as HTMLElement;
          if (element) {
            snapItemWidth.value = element.clientWidth;
          }
        });
      }
    };

    /** -- Pagination -- */
    const scroller = ref(null);
    const scrollLeft = ref(0);
    const scrollLeftMax = ref(0);
    const itemsScrolled = ref(0);

    const isTouchDevice = computed(
      () => window.localStorage.getItem("USER_CAN_TOUCH") === "true"
    );

    const scrollIndex = ref(0);

    const totalPages = computed(() =>
      Math.ceil(numberOfItems.value / itemsPerPage.value)
    );

    const showLeftArrow = computed(() => itemsScrolled.value > 0);
    const showRightArrow = computed(() => {
      const baseCondition = totalPages.value > 1;
      if (scroller.value) {
        return baseCondition && scrollLeft.value < scrollLeftMax.value;
      }
      return baseCondition;
    });

    const disabled = ref(false);
    let timer;
    const paginate = (direction: "right" | "left") => {
      disabled.value = true;
      if (!timer) {
        timer = setTimeout(() => {
          disabled.value = false;
          clearTimeout(timer);
          timer = undefined;
        }, 300);

        let scrolledItemsDifference =
          itemsScrolled.value - scrollIndex.value * itemsPerPage.value;

        if (scrolledItemsDifference % itemsPerPage.value) {
          scrolledItemsDifference = 0;
        }

        const currentPosition = itemsScrolled.value * snapItemWidth.value;

        let nextPosition;
        if (direction === "right") {
          scrollIndex.value++;
          nextPosition =
            currentPosition + snapItemWidth.value * itemsPerPage.value;
        } else {
          scrollIndex.value--;
          nextPosition =
            currentPosition - snapItemWidth.value * itemsPerPage.value;
        }

        scroller.value.scrollBy({
          left: nextPosition - currentPosition,
          top: 0,
          behavior: "smooth"
        });
      }
    };

    const updatedShowRightArrow = () => {
      scrollLeft.value = scroller.value.scrollLeft;
      scrollLeftMax.value =
        scroller.value.scrollWidth - scroller.value.clientWidth;
    };
    /** -- End Pagination -- */

    onMounted(() => {
      // adds "snap-start" class after the component was mounted to prevent
      // snapping to a random item before the list is fully mounted
      updateSnapItemsWidth();
      window.addEventListener("resize", updateSnapItemsWidth);

      updatedShowRightArrow();

      let scrollTimer;
      const updateIndex = () => {
        clearTimeout(scrollTimer); // debounce
        scrollTimer = setTimeout(() => {
          itemsScrolled.value = Math.ceil(
            scroller.value.scrollLeft / snapItemWidth.value
          );
          const pagesScrolled = Math.floor(
            itemsScrolled.value / itemsPerPage.value
          );
          scrollIndex.value = pagesScrolled;
          updatedShowRightArrow();
        }, 200);
      };
      scroller.value.addEventListener("scroll", updateIndex);
    });

    return {
      gridContainerStyle,
      gridColumnStyle,
      snapItems,
      scroller,
      isTouchDevice,
      paginate,
      showLeftArrow,
      showRightArrow,
      disabled
    };
  }
});
