














import Vue, { PropType } from "vue";
import { Location } from "vue-router";
import UiMenu from "../ui/UiMenu.vue";
import Toggle from "./Toggle.vue";

export default Vue.extend({
  components: {
    UiMenu,
    Toggle
  },
  props: {
    printRoute: {
      type: Object as PropType<Location> | null,
      required: true
    }
  },
  data() {
    return {
      open: false
    };
  },
  computed: {
    mailTo(): string {
      const email = this.$t("reportEmail.email") as string;
      const subject = this.$t("reportEmail.subject") as string;
      const body = (this.$t("reportEmail.body") as string).replace(
        "{URL}",
        encodeURIComponent(window.location.href)
      );
      return `mailto:${email}?subject=${subject}&body=${body}`;
    },
    options(): { id: string; label: string }[] {
      const opts = [
        {
          id: "REPORT",
          label: this.$t("summaryItem.reportError") as string
        }
      ];

      if (this.printRoute) {
        opts.push({
          id: "PRINT",
          label: this.$t("summaryItem.print") as string
        });
      }

      return opts;
    }
  },
  methods: {
    select(option) {
      if (option.id === "REPORT") {
        window.location.assign(this.mailTo);
      } else if (option.id === "PRINT") {
        this.$router.push(this.printRoute);
      }
    }
  }
});
