






















import { computed, defineComponent } from "@vue/composition-api";
import store from "@/store";
import NavItem from "@/components/nav/NavItem.vue";
import ExpandableNavItem from "@/components/nav/ExpandableNavItem.vue";

export default defineComponent({
  components: {
    NavItem,
    ExpandableNavItem
  },
  emits: ["click"],
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  setup(_, { emit }) {
    const isAuthorized = computed(() => store.getters["auth/isAuthorized"]);

    const emitClick = item => {
      if (!("children" in item)) {
        return emit("click");
      }
    };

    return {
      isAuthorized,
      emitClick
    };
  }
});
