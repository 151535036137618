



























import { defineComponent, computed, ref } from "@/composition-api";
import FcCategoryTabs from "@/components/FcCategoryTabs/FcCategoryTabs.vue";
import FcTrainingTeaserList from "@/components/FcTrainingTeaserList/FcTrainingTeaserList.vue";
import FcLearningTopicHeader from "@/components/FcLearningTopicHeader/FcLearningTopicHeader.vue";
import FcRichText from "@/components/FcRichText/FcRichText.vue";
import FcLearningGoals from "@/components/FcLearningGoals/FcLearningGoals.vue";

export default defineComponent({
  props: {
    topicId: {
      type: String,
      required: true
    }
  },
  components: {
    "category-tabs": FcCategoryTabs,
    "training-teaser-list": FcTrainingTeaserList,
    "learning-topic-header": FcLearningTopicHeader,
    FcRichText,
    "learning-goals": FcLearningGoals
  },
  watch: {
    topicId(value) {
      this.getNewPage(value);
    }
  },
  setup(props, { root }) {
    const isLoading = ref(true);

    const getNewPage = pageId => {
      isLoading.value = true;
      root.$store
        .dispatch("learningTopic/fetchLearningTopicDetailPage", pageId)
        .finally(() => {
          isLoading.value = false;
        });
      root.$store.dispatch("training/getVisistedTrainingStatus");
    };
    getNewPage(props.topicId);

    const page = computed(() => {
      const currentPage = root.$store.getters["learningTopic/currentPage"](
        props.topicId
      );

      if (isLoading.value) {
        return {
          ...currentPage,
          trainings: Array(6)
            .fill({})
            .map(() => ({
              id: String(Math.floor(Math.random() * 999))
            }))
        };
      }
      return currentPage;
    });

    const visitedTrainingIds = computed(
      () => root.$store.state.training.visitedTrainingIds
    );

    return {
      getNewPage,
      page,
      visitedTrainingIds,
      isLoading
    };
  }
});
