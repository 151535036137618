









import { defineComponent, computed, ref } from "@vue/composition-api";
import ToolkitList from "../components/FcToolkitTeaserList/FcToolkitTeaserList.vue";

export default defineComponent({
  components: {
    ToolkitList
  },
  setup(props, { root }) {
    const isLoading = ref(true);
    root.$store.dispatch("tool/fetchToolHubPage").finally(() => {
      isLoading.value = false;
    });

    const teasers = computed(() => {
      if (isLoading.value) {
        return Array(4).fill(null);
      }
      return root.$store.getters["tool/toolkitTeasers"];
    });

    return {
      teasers,
      isLoading
    };
  }
});
