





















































import { VideoChapter } from "@/interfaces";
import Vue, { PropType } from "vue";
import FcVideoChapterList from "./FcVideoChapterList/FcVideoChapterList.vue";
import FcSectionHeader from "@/components/FcSectionHeader/FcSectionHeader.vue";
import UiCollapsible from "@/components/ui/UiCollapsible.vue";
import throttle from "lodash.throttle";
import useBreakpoint from "@/composition/useBreakpoints";
import {
  defineComponent,
  ref,
  computed,
  watch,
  onMounted,
  onUnmounted,
  nextTick
} from "@/composition-api";

export default defineComponent({
  components: {
    "chapter-list": FcVideoChapterList,
    "section-header": FcSectionHeader,
    UiCollapsible
  },
  props: {
    videoId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    chapters: {
      type: Array as PropType<VideoChapter[]>,
      default: () => []
    }
  },
  setup(props, { root }) {
    const currentStart = ref(0);
    const autoplay = ref(0);
    const { isDesktop } = useBreakpoint();

    const availableChapters = computed(() => {
      return props.chapters.map(chapter => {
        const c = { ...chapter };
        c.active = currentStart.value === c.chapter;
        return c;
      });
    });

    const parsedVideoLink = computed(() => {
      const regexParse = props.videoId.match(/secret\/(\w+)\/(\w+)/);
      if (!regexParse) return [];

      if (regexParse.length === 3) {
        const [_, videoId, token] = regexParse;
        return [videoId, token];
      }
      return [];
    });

    const parsedToken = computed(() => parsedVideoLink.value[1]);

    const parsedVideoId = computed(() => parsedVideoLink.value[0]);

    const src = computed(() => {
      return `//www.dpa-video.com/68095820.ihtml/player.html?token=${parsedToken.value}&source=embed&photo%5fid=${parsedVideoId.value}&start=${currentStart.value}&autoPlay=${autoplay.value}`;
    });

    const handleChapterUpdate = (seconds: number) => {
      autoplay.value = 1;
      currentStart.value = 0;
      nextTick(() => {
        currentStart.value = seconds;
      });
    };

    const videoContainerRef = ref(null);
    const sectionHeaderRef = ref(null);
    const chapterListRef = ref(null);
    const resizeHandler = () => {
      if (!chapterListRef.value) return;
      const chapterList = (chapterListRef.value as Vue).$el as HTMLElement;

      if (isDesktop.value) {
        if (!videoContainerRef.value) return;
        const videoContainer = videoContainerRef.value as HTMLElement;
        const videoContainerHeight = videoContainer.clientHeight;

        if (!sectionHeaderRef.value) return;
        const sectionHeader = (sectionHeaderRef.value as Vue).$el;
        const sectionHeaderHeight = sectionHeader.clientHeight;

        const listHeight = videoContainerHeight - sectionHeaderHeight;

        const originalChapterListHeight = chapterList.clientHeight;

        chapterList.classList.toggle(
          "pr-6",
          originalChapterListHeight > listHeight
        );

        chapterList.style.height = `${listHeight}px`;
      } else {
        chapterList.style.height = "auto";
      }
    };

    const timestamp = computed(() => {
      // Crashes storybook if tries to access root.$route directly
      // because of StoryRouter decorator which does not support composition api
      if (Object.keys(root).includes("$route")) {
        return root.$route.query.s;
      }
      return null;
    });
    watch(timestamp, (val: string) => {
      handleChapterUpdate(parseInt(val, 10));
    });

    onMounted(() => {
      window.addEventListener(
        "resize",
        throttle(() => resizeHandler())
      );
      nextTick(() => resizeHandler());
    });

    onUnmounted(() => {
      window.removeEventListener(
        "resize",
        throttle(() => resizeHandler())
      );
    });

    return {
      isDesktop,
      currentStart,
      autoplay,
      availableChapters,
      src,
      handleChapterUpdate,
      videoContainerRef,
      sectionHeaderRef,
      chapterListRef
    };
  }
});
