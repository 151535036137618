<template>
  <div class="pb-12 mx-auto" style="max-width: 652px;">
    <h1 class="mt-4 mb-4 text-2xl font-medium text-gray-900 md:mt-8">
      Herzlich willkommen!
    </h1>
    <div class="p-8 bg-white">
      <div class="prose-sm prose">
        <h2>Was ist dpa-factify?</h2>
        <p>
          Wir bieten Journalistinnen und Journalisten eine Trainingsplattform
          für die digitale Recherche und Verifikation an.
        </p>
        <h2>Warum sehe ich hier keine Inhalte?</h2>
        <p>
          Du hast zwar offensichtlich eine dpa-ID, bist aber hier auf
          dpa-factify noch nicht als Nutzerin oder Nutzer registriert.
        </p>
        <h2>Was muss ich jetzt tun?</h2>
        <p>
          Schreib uns gerne von Deiner Dienstadresse aus eine Mail an
          factify@dpa.com. Wir nehmen dann Kontakt zu Dir auf.
        </p>
        <div class="mt-8 text-center">
          <a
            href="mailto:factify@dpa.com?subject=Einladung zur Faktencheck-Community auf Slack&body=Liebes%20Faktencheck-Team%20der%20dpa%2C%0A%0A%0Aich%20arbeite%20als%20%5B%E2%80%A6%5D%20f%C3%BCr%20%5B%E2%80%A6%5D%0A%0Aund%20bin%20an%20Eurer%20Faktencheck-Trainingsplattform%20interessiert.%20K%C3%B6nntet%20Ihr%20mir%20eine%20Einladung%20schicken%3F%0A%0AVielen%20Dank.%0A%0A%0AMit%20freundlichen%20Gr%C3%BC%C3%9Fen%2C%0A%0A%5B%E2%80%A6%5D"
            class="inline-block px-4 py-1 bg-blue hover:bg-blue-dark cta"
            >Anfrage stellen</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "Unauthorized"
});
</script>
