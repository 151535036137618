<template>
  <button
    class="flex items-center justify-center w-8 h-8 rounded-full hover:bg-black hover:text-gray-400"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {};
</script>
