import { computed } from "@/composition-api";

export default ({
  disabled = false,
  size,
  color
}: {
  disabled?: boolean;
  size: string;
  color: string;
}) => {
  const variantClassName = computed(() => {
    const variant = disabled ? "disabled" : color;

    const map = {
      blue: "text-white bg-blue hover:bg-blue-dark",
      white: "text-blue bg-white hover:text-blue-dark",
      gray: "text-blue bg-white border-2 hover:text-blue-dark",
      disabled: "text-gray-700 bg-gray-300 cursor-default"
    };

    return map[variant];
  });

  const sizeClassName = computed(() => {
    const lookup = {
      small: "h-8",
      medium: "h-10",
      large: "h-12"
    };

    if (!size) return lookup["medium"];

    return lookup[size];
  });

  return {
    variantClassName,
    sizeClassName
  };
};
