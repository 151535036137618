export const getIncludedObjectAttributes = (included, id) => {
  return included.find(obj => obj.id === id)?.attributes;
};

export const getIncludedObjectRelationships = (included, id) => {
  return included.find(obj => obj.id === id)?.relationships;
};

export const getChapterSeconds = (hours, minutes, seconds) => {
  let secs = seconds || 0;
  if (hours > 0) {
    secs += hours * 3600;
  }
  if (minutes > 0) {
    secs += minutes * 60;
  }
  return secs;
};

const sortByDate = (a, b, property) => {
  const timeA = new Date(a[property]).getTime();
  const timeB = new Date(b[property]).getTime();
  if (timeA > timeB) return -1;
  if (timeA < timeB) return 1;
  return 0;
};

export const sortByCreatedDate = (a, b) => {
  return sortByDate(a, b, "created");
};

export const sortByChangedDate = (a, b) => {
  return sortByDate(a, b, "changed");
};
