

















import { defineComponent } from "@/composition-api";

export default defineComponent({
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
});
