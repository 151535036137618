




























import Vue from "vue";
export default Vue.extend({
  props: {
    links: {
      type: Array,
      default: () => []
    }
  }
});
