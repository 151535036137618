






import Vue, { PropType } from "vue";
import UiGrid from "@/components/ui/UiGrid.vue";
import UiSlider from "@/components/ui/UiSlider.vue";
import FcLearningTopicTeaser from "@/components/FcLearningTopicTeaser/FcLearningTopicTeaser.vue";
import FcLearningTopicTeaserSkeleton from "@/components/FcLearningTopicTeaser/FcLearningTopicTeaserSkeleton.vue";
import { Identifiable, LearningTopicTeaserInterface } from "@/interfaces";

export default Vue.extend({
  components: {
    UiGrid,
    UiSlider,
    FcLearningTopicTeaser,
    FcLearningTopicTeaserSkeleton
  },
  props: {
    topics: {
      type: Array as PropType<LearningTopicTeaserInterface[] | Identifiable[]>,
      default: () => []
    },
    scrollable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    component(): string {
      const baseComponent = "fc-learning-topic-teaser";
      return this.loading ? `${baseComponent}-skeleton` : baseComponent;
    }
  }
});
