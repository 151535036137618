




import { defineComponent } from "@vue/composition-api";
import FcFormRequestPassword from "@/components/FcForm/FcFormRequestPassword.vue";

export default defineComponent({
  components: {
    FcFormRequestPassword
  }
});
