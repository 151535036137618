











import { PropType } from "vue";
import { Location } from "vue-router";
import { defineComponent } from "@/composition-api";
import useBreakpoints from "@/composition/useBreakpoints";
import { CategoryInterface } from "@/interfaces";
import CategoryTabs from "../FcCategoryTabs/FcCategoryTabs.vue";
import ContextButtons from "../FcContextButtons/FcContextButtons.vue";

export default defineComponent({
  components: {
    CategoryTabs,
    ContextButtons
  },
  props: {
    categories: {
      type: Array as PropType<CategoryInterface[]>,
      default: () => []
    },
    printRoute: {
      type: Object as PropType<Location>,
      required: true
    },
    showContextButtons: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { isDesktop } = useBreakpoints();
    return {
      isDesktop
    };
  }
});
