


















import { defineComponent } from "@/composition-api";
import store from "@/store";
import useBreakpoints from "@/composition/useBreakpoints";
import UiIconClose from "../ui/UiIconClose.vue";

export default defineComponent({
  components: {
    UiIconClose
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },

  setup() {
    const { isDesktop } = useBreakpoints();
    const close = () => {
      store.commit("navigation/setIsLeftNavOpen", false);
    };

    return {
      close,
      isDesktop
    };
  }
});
