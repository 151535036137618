



























import Vue from "vue";
export default Vue.extend({
  props: {
    label: {
      type: String,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    },
    href: {
      type: String,
      required: true
    },
    external: {
      type: Boolean,
      default: false
    },
    navigate: {
      type: Function,
      required: true
    }
  },
  methods: {
    handleClick(e) {
      if (!this.external && this.navigate) {
        this.navigate(e);
        this.$emit("click");
      }
    }
  }
});
