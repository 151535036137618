import {
  LearningTopicHub,
  LearningTopicInterface,
  LearningTopicPage,
  LearningTopicsSection,
  LearningTopicState,
  TrainingPage,
  CheatSheetPageInterface
} from "@/interfaces";
import { LearningTopicHttp } from "@/http/client/learning-topic.http";
import {
  getCategories,
  mapToTrainingTeasers,
  removeListTagsFromTopic
} from "../utils";

const state: LearningTopicState = {
  learningTopicHub: {} as LearningTopicHub,
  learningTopics: []
};

const getters = {
  learningTopicsSections: (
    state,
    getters,
    _,
    rootGetters
  ): LearningTopicsSection[] => {
    if (!state.learningTopicHub.learningTopicLists) return [];

    return state.learningTopicHub.learningTopicLists.map(list => {
      const toolkits = rootGetters["tool/toolkitsAsLearningTopicTeasers"](
        list.tags.map(({ id }) => id)
      );

      const topics = getters.getTopicsForTags(list.tags);
      const groomedTopics = removeListTagsFromTopic(list.tags, [
        ...topics,
        ...toolkits
      ]);
      return {
        ...list,
        topics: groomedTopics
      };
    });
  },
  getTopicsForTags: state => (tags): LearningTopicInterface => {
    const tagsIds = tags.map(({ id }) => id);
    return state.learningTopics.filter(({ tags }) =>
      tags.some(({ id }) => tagsIds.includes(id))
    );
  },
  learningTopicPages: (state, _, rootState): LearningTopicPage[] => {
    return state.learningTopics.map(topic => {
      const trainings = rootState.practicalExample.practicalExamples.filter(
        training =>
          training.learningTopics.map(({ id }) => id).includes(topic.id)
      );

      return {
        ...topic,
        trainings: mapToTrainingTeasers(topic, trainings),
        categories: getCategories(topic, trainings)
      };
    });
  },
  learningTopicTrainingsPage: (state, _, rootState) => (
    topicId
  ): TrainingPage => {
    const learningTopic = state.learningTopics.find(({ id }) => id === topicId);
    if (!learningTopic) return {} as TrainingPage;

    const interactiveContent = rootState.practicalExample.practicalExamples.filter(
      example => example.learningTopics.map(({ id }) => id).includes(topicId)
    );

    return {
      teaserList: {
        scrollable: true,
        trainings: mapToTrainingTeasers(learningTopic, interactiveContent)
      },
      categories: getCategories(learningTopic, interactiveContent)
    };
  },
  learningTopicCheatSheetPage: (state, _, rootState) => (
    topicId
  ): CheatSheetPageInterface => {
    const learningTopic = state.learningTopics.find(({ id }) => id === topicId);

    if (!learningTopic) return {} as CheatSheetPageInterface;

    const trainings = rootState.practicalExample.practicalExamples.filter(
      example => example.learningTopics.map(({ id }) => id).includes(topicId)
    );

    return {
      title: learningTopic.title,
      illustrationUrl: learningTopic.headerIllustrationUrl,
      primaryColor: learningTopic.primaryColor,
      categories: getCategories(learningTopic, trainings),
      cheatSheets: learningTopic.cheatSheets,
      toolList: {
        items: rootState.tool.tools
      }
    };
  },
  currentPage: (_, getters) => (pageId): LearningTopicPage | undefined =>
    getters.learningTopicPages.find(page => page.id === pageId)
};

const actions = {
  fetchLearningTopicPageHub: async ({ commit, dispatch }) => {
    const learningTopicHub = await LearningTopicHttp.getLearningTopicHub();
    const tagsIds = new Set(
      learningTopicHub.learningTopicLists
        .map(list => list.tags.map(tag => tag.id))
        .flat()
    );
    await Promise.all([
      dispatch("fetchLearningTopicsForTags", [...tagsIds]),
      dispatch("tool/fetchToolkitsForTags", [...tagsIds], { root: true })
    ]);
    commit("setLearningTopicHub", learningTopicHub);
  },
  fetchLearningTopicsForTags: async ({ commit }, tagsIds) => {
    const topics = await LearningTopicHttp.getLearningTopics([
      { path: "field_tags.id", value: tagsIds, operator: "IN" }
    ]);
    commit("setLearningTopics", topics);
  },
  fetchLearningTopics: async ({ commit }) => {
    const learningTopics = await LearningTopicHttp.getLearningTopics();
    commit("setLearningTopics", learningTopics);
  },
  fetchLearningTopicDetailPage: async (
    { commit, getters, dispatch },
    topicId
  ) => {
    if (!getters.currentPage(topicId)) {
      const topic = await LearningTopicHttp.getLearningTopic(topicId);
      commit("setSingleLearningTopicDetails", topic);
    }

    await Promise.all([
      dispatch("practicalExample/fetchTrainingsForLearningTopic", topicId, {
        root: true
      }),
      dispatch("tool/fetchToolsForLearningTopic", topicId, { root: true })
    ]);

    return getters.currentPage(topicId);
  }
};

const mutations = {
  setLearningTopicHub(state, topicHub: LearningTopicHub) {
    state.learningTopicHub = topicHub;
  },
  setLearningTopics(state, topics: LearningTopicInterface[]) {
    state.learningTopics = topics;
  },
  setSingleLearningTopicDetails(state, topic: LearningTopicInterface) {
    state.learningTopics.push(topic);
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
};
