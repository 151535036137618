import { computed } from "@vue/composition-api";

const SUBDOMAINS_WITHOUT_DPA_ID = [
  "apa",
  "apa.devel.dpafaktencheck.oterma",
  "apa-staging-factify",
  "apa.drupal-10-upgrade.dpafaktencheck.oterma"
];

export default () => {
  // check for apa and give one set of navigation guards
  // otherwise give dpa navigation guards
  const subdomain = window.location.hostname
    .split(".")
    .slice(0, -2)
    .join(".");

  const useDpaId = computed(() => {
    if (window.location.hostname.includes("localhost")) {
      return false;
    }
    return !SUBDOMAINS_WITHOUT_DPA_ID.includes(subdomain);
  });

  return { useDpaId };
};
