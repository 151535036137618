export enum ApiEndpoint {
  LearningTopicHub = "/jsonapi/node/learning_topic_hub",
  LearningTopic = "/jsonapi/node/learning_topic",
  TrainingVideo = "/jsonapi/training/video",
  ToolkitPage = "/jsonapi/node/toolkit_page",
  Tool = "/jsonapi/tool/tool",
  PracticalExamplesHub = "/jsonapi/config_pages/practical_examples",
  InteractiveContent = "/jsonapi/training/interactive_content",
  VisitedTraining = "/jsonapi/activity/activity",
  Tag = "/jsonapi/taxonomy_term/tags",
  SessionToken = "/session/token"
}
