














import Vue, { PropType } from "vue";
import UiGrid from "@/components/ui/UiGrid.vue";
import FcSectionHeader from "@/components/FcSectionHeader/FcSectionHeader.vue";
import FcCheatSheetEntry from "./FcCheatSheetEntry/FcCheatSheetEntry.vue";
import { CheatSheetInterface, CheatSheetListLayout } from "@/interfaces";

export default Vue.extend({
  components: {
    "section-header": FcSectionHeader,
    entry: FcCheatSheetEntry,
    UiGrid
  },
  props: {
    listTitle: {
      type: String,
      required: true
    },
    layout: {
      type: String as PropType<CheatSheetListLayout>,
      default: CheatSheetListLayout.One
    },
    entries: {
      type: Array as PropType<CheatSheetInterface[]>,
      default: () => []
    }
  },
  computed: {
    classes() {
      const classes = ["mt-8"];

      if (this.layout === CheatSheetListLayout.TwoThirdsRight) {
        classes.push("md:grid-cols-1 print:grid-cols-1 print:gap-12");
      } else if (this.layout === CheatSheetListLayout.TwoThirdsLeft) {
        classes.push("md:grid-cols-1 print:grid-cols-1 print:gap-12");
      } else {
        classes.push("lg:grid-cols-2 print:grid-cols-2 print:gap-12");
      }

      return classes;
    }
  }
});
