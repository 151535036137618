import Vue from "vue";
import VueMatomo from "vue-matomo";
import App from "./App.vue";
import router from "./router";
import store from "@/store";
import i18n from "@/i18n";
import "@/assets/styles/index.css";
import { matomoConfig } from "../config";
import smoothscroll from "smoothscroll-polyfill";

smoothscroll.polyfill();

Vue.config.productionTip = false;

window.localStorage.setItem("USER_CAN_TOUCH", "false");
window.addEventListener(
  "touchstart",
  function onFirstTouch() {
    localStorage.setItem("USER_CAN_TOUCH", "true");

    // we only need to know once that a human touched the screen,
    // so we can stop listening now
    window.removeEventListener("touchstart", onFirstTouch, false);
  },
  false
);

async function awaitStageConfig() {
  if (!store.state.stageConfig.baseUrl) {
    await store.dispatch("stageConfig/fetchStageConfig");
  }
}

awaitStageConfig()
  .then(function success() {
    if (store.state.stageConfig.stage === "PROD") {
      const {
        matomoSiteId: siteId,
        matomoHost: host
      } = store.state.stageConfig;

      Vue.use(VueMatomo, matomoConfig(router, { siteId, host }));
    }

    new Vue({
      i18n,
      router,
      store,
      render: h => h(App)
    }).$mount("#app");
  })
  .catch(err => console.log(err));
