import { Module } from "vuex";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { StageConfigState } from "./types";
import { RootState } from "../../types";

export const state: StageConfigState = {
  stage: "LOCAL",
  // TODO: Should be single source of truth
  pubKey:
    "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAuowF8BX66ikZSJ1eBQKxxx23ZfRSe0jG+xQARyRyqSny4pX0dlyopo3xuq3QxABnZbPPOmC8jJ3RoDQFJpY0/nH4FaKMWemlhxvwjWdutgfhhuYz7p6KuvlP6RTHTKM8JnGZUvmhx2OQRDM2R54nV//XYBtXxb+8aV9bFEuk2lwQbWeGjlIOhu5Z2LZCSW9knZKe3i/jwcorjo0AhBHMeBYR0eG+rdfO9YInH13mMIfxo+emeAcEd/+AuK1yWSifQoLtexJU4eWcz4CVOjiKHMHtSF30DFkuc2/gOh9w3W0nHqx5ky6VR6EEh/h80t6EwHPuD/3I0kSxxJEnbjrSWwIDAQAB\n-----END PUBLIC KEY-----",
  ssoURL: "https://sso.dpa-id.de/cas",
  profileUrl: "https://sso.dpa-id.de/useradmin/myprofile",
  apiBaseUrl: "",
  baseUrl: "",
  matomoHost: "",
  matomoSiteId: ""
};

const namespaced = true;

export const stageConfig: Module<StageConfigState, RootState> = {
  namespaced,
  state,
  actions,
  mutations
};
