




















































import { defineComponent, ref, computed } from "@/composition-api";
import { mixin as clickaway } from "vue-clickaway";

export default defineComponent({
  mixins: [clickaway],
  props: {
    options: {
      type: Array,
      default: () => []
    },
    selectable: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "small",
      validator: (val: string) => ["small", "medium"].includes(val)
    }
  },
  setup(props, { emit }) {
    const isOpen = ref(false);

    const open = () => {
      emit("open");
      isOpen.value = true;
    };

    const close = () => {
      emit("close");
      isOpen.value = false;
    };

    const toggle = () => {
      if (isOpen.value) {
        close();
      } else {
        open();
      }
    };

    const select = option => {
      emit("optionSelect", option);
      close();
    };

    const size = computed(() => props.size);

    const sizeClasses = computed(() => {
      const lookup = {
        small: "w-40",
        medium: "w-48"
      };

      return lookup[size.value];
    });

    const optionClasses = ({ selected }) => {
      return [
        selected ? "text-gray-600" : "text-gray-900",
        props.selectable ? "pr-9" : "pr-3"
      ];
    };

    return { select, isOpen, toggle, close, sizeClasses, optionClasses };
  }
});
