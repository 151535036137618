

























import ToolListItem from "@/components/FcToolListItem/FcToolListItem.vue";
import ToolListItemSkeleton from "@/components/FcToolListItem/FcToolListItemSkeleton.vue";
import SectionHeader from "@/components/FcSectionHeader/FcSectionHeader.vue";
import UiCollapsible from "@/components/ui/UiCollapsible.vue";
import useBreakpoints from "@/composition/useBreakpoints";
import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {
    SectionHeader,
    ToolListItem,
    ToolListItemSkeleton,
    UiCollapsible
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    items: {
      type: Array,
      default: () => []
    },
    startOpen: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { isMobile } = useBreakpoints();

    const list = computed(() => {
      if (isMobile.value) {
        return [props.items];
      }

      const lists = [[], []];
      props.items.forEach((item, i) => {
        if (i % 2 === 0) {
          lists[0].push(item);
        } else {
          lists[1].push(item);
        }
      });
      return lists;
    });

    const component = computed(() => {
      const baseComponent = "tool-list-item";
      return props.loading ? `${baseComponent}-skeleton` : baseComponent;
    });

    return {
      list,
      isMobile,
      component
    };
  }
});
