




































import { defineComponent, computed, ref } from "@vue/composition-api";
import usePrint from "@/composition/usePrint";
import ToolList from "../components/FcToolList/FcToolList.vue";
import LearningTopicHeader from "../components/FcLearningTopicHeader/FcLearningTopicHeader.vue";
import FcRichText from "@/components/FcRichText/FcRichText.vue";
import FcCheatSheetSection from "@/components/FcCheatSheet/FcCheatSheetSection/FcCheatSheetSection.vue";

export default defineComponent({
  components: {
    LearningTopicHeader,
    ToolList,
    FcRichText,
    "cheat-sheet-section": FcCheatSheetSection
  },
  props: {
    toolId: {
      type: String,
      required: true
    },
    print: {
      type: Boolean,
      required: false
    }
  },
  watch: {
    toolId(newVal) {
      this.getToolListPage(newVal);
    }
  },
  setup(props, { root }) {
    const isLoading = ref(true);

    const getToolListPage = pageId => {
      root.$store.dispatch("tool/fetchToolListPage", pageId).finally(() => {
        isLoading.value = false;
      });
    };
    getToolListPage(props.toolId);

    const page = computed(() => {
      if (isLoading.value) {
        return {
          title: "...",
          toolLists: [
            {
              title: "...",
              items: Array(5).fill(null)
            }
          ]
        };
      }
      return root.$store.getters["tool/toolListPage"];
    });

    let content = null;
    if (props.print) {
      const { content: printContent } = usePrint();
      content = printContent;
    }

    return {
      getToolListPage,
      page,
      content,
      isLoading
    };
  }
});
