import { ApiEndpoint } from "../endpoint";
import { DrupalJsonApiParams } from "drupal-jsonapi-params";
import useApi from "@/composition/useApi";
import {
  ToolHubResponseTranslator,
  ToolsResponseTranslator,
  ToolListResponseTranslator,
  ToolListPagesResponseTranslator
} from "../translators/tool";

export class ToolHttp {
  static async getToolkitsNavigation() {
    const { client } = useApi();
    const apiParams = new DrupalJsonApiParams();
    apiParams.addFields("node--toolkit_page", ["id", "title"]);
    const queryString = apiParams.getQueryString({ encode: false });
    const { data } = await client.get(
      `${ApiEndpoint.ToolkitPage}?${queryString}`
    );
    return data;
  }

  static async getToolHub(filters = []) {
    const { client } = useApi();
    const apiParams = new DrupalJsonApiParams();
    apiParams.addInclude([
      "field_teaser_svg",
      "field_thumbnail_svg",
      "field_tags"
    ]);
    if (filters.length) {
      filters.forEach(filter => {
        apiParams.addFilter(filter.path, filter.value, filter.operator);
      });
    }
    const queryString = apiParams.getQueryString({ encode: false });

    const data = await client.get(`${ApiEndpoint.ToolkitPage}?${queryString}`);

    const toolHub = ToolHubResponseTranslator(data);
    return toolHub;
  }

  static async getToolListPage(id) {
    const { client } = useApi();
    const apiParams = new DrupalJsonApiParams();
    apiParams.addInclude([
      "field_header_svg",
      "field_cheat_sheet_sections.field_cheat_sheets.field_image",
      "field_teaser_svg",
      "field_thumbnail_svg",
      "field_tool_list_sections.field_filter_tags",
      "field_tabs"
    ]);
    const queryString = apiParams.getQueryString({ encode: false });

    const data = await client.get(
      `${ApiEndpoint.ToolkitPage}/${id}?${queryString}`
    );

    const toolHub = ToolListResponseTranslator(data);
    return toolHub;
  }

  static async getToolListPages(filters) {
    const { client } = useApi();
    const apiParams = new DrupalJsonApiParams();
    apiParams.addInclude([
      "field_header_svg",
      "field_cheat_sheet_sections.field_cheat_sheets.field_image",
      "field_teaser_svg",
      "field_thumbnail_svg",
      "field_tool_list_sections.field_filter_tags",
      "field_tabs"
    ]);
    if (filters.length) {
      filters.forEach(filter => {
        apiParams.addFilter(filter.path, filter.value, filter.operator);
      });
    }
    const queryString = apiParams.getQueryString({ encode: false });

    const data = await client.get(`${ApiEndpoint.ToolkitPage}?${queryString}`);

    const toolkits = ToolListPagesResponseTranslator(data);
    return toolkits;
  }

  static async getTools(filters = [], sort = null) {
    const { client } = useApi();
    const apiParams = new DrupalJsonApiParams();
    apiParams.addInclude(["field_icon", "field_tags", "field_type"]);
    if (filters.length) {
      filters.forEach(filter => {
        apiParams.addFilter(filter.path, filter.value, filter.operator);
      });
    }
    if (sort) {
      apiParams.addSort(sort.path, sort.direction || "DESC");
    }
    const queryString = apiParams.getQueryString({ encode: false });

    const data = await client.get(`${ApiEndpoint.Tool}?${queryString}`);

    const tools = ToolsResponseTranslator(data);
    return tools;
  }
}
