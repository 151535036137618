import {
  PracticalExample,
  PracticalExamplesHub,
  PracticalExamplesList
} from "@/interfaces";
import {
  getIncludedObjectAttributes,
  getIncludedObjectRelationships
} from "./utils";

export const PracticalExamplesHubResponseTranslator = (
  response
): PracticalExamplesHub => {
  const { included, data } = response;
  const [page] = data;
  const { attributes, relationships } = page;
  return {
    id: page.id,
    title: attributes.label,
    lists: relationships.field_training_lists.data.map(
      (list): PracticalExamplesList => {
        const listAttributes = getIncludedObjectAttributes(included, list.id);
        const listRelationships = getIncludedObjectRelationships(
          included,
          list.id
        );

        return {
          id: list.id,
          title: listAttributes.field_title,
          scrollable: listAttributes.field_list_style === "slider",
          tags: listRelationships.field_filter_tags.data
            .map(tag => {
              const tagAttributes = getIncludedObjectAttributes(
                included,
                tag.id
              );
              if (!tagAttributes) return null;

              return {
                id: tag.id,
                label: tagAttributes.name
              };
            })
            .filter(Boolean),
          trainings: []
        };
      }
    )
  };
};

export const PracticalExamplesResponseTranslator = (
  response
): PracticalExample[] => {
  const { included, data } = response;

  return data.map(
    (example): PracticalExample => {
      const { attributes, relationships } = example;
      const {
        field_learning_topics: fieldLearningTopics,
        field_thumbnail: fieldThumbnail,
        field_tags: fieldTags
      } = relationships;

      const thumbnailUrl = getIncludedObjectAttributes(
        included,
        fieldThumbnail.data.id
      )?.uri.url;

      const tags = fieldTags.data
        .map(tag => {
          const tagAttribute = getIncludedObjectAttributes(included, tag.id);
          if (!tagAttribute) return null;
          return {
            id: tag.id,
            label: tagAttribute.name
          };
        })
        .filter(Boolean);

      const learningTopics = fieldLearningTopics.data.map(topic => {
        const attr = getIncludedObjectAttributes(included, topic.id);
        return {
          id: topic.id,
          title: attr.title
        };
      });

      return {
        id: example.id,
        title: attributes.name,
        embedCode: attributes.field_embed_code,
        thumbnailUrl,
        tags,
        learningTopics: learningTopics,
        created: attributes.created,
        changed: attributes.changed
      };
    }
  );
};
