








































































import { defineComponent } from "@vue/composition-api";
import UiInput from "@/components/ui/UiInput.vue";
import UiButton from "@dpa-id-components/ui-button";
import FcPublicHeader from "./lib/FcPublicHeader.vue";
import FcPublicFooter from "./lib/FcPublicFooter.vue";
import FcPublicCard from "./lib/FcPublicCard.vue";
import ExclamationIcon from "@/assets/vectors/icons/exclamation.svg?inline";
import store from "@/store";
import { Routes } from "@/router/types";

export default defineComponent({
  components: {
    UiButton,
    UiInput,
    FcPublicHeader,
    FcPublicFooter,
    FcPublicCard,
    ExclamationIcon
  },

  data() {
    return {
      password: "",
      confirmPassword: "",
      errorMessage: {
        general: "",
        confirmPassword: ""
      },
      passwordUpdated: false,
      tempPassword: "",
      drupalUser: ""
    };
  },

  mounted() {
    const { t, u } = this.$route.query;
    if (t && u) {
      this.tempPassword = t as string;
      this.drupalUser = u as string;
    }
  },

  methods: {
    validate() {
      this.errorMessage = {
        general: "",
        confirmPassword: ""
      };

      const isValid = this.password && this.password === this.confirmPassword;
      if (!isValid) {
        this.$set(
          this.errorMessage,
          "confirmPassword",
          this.$t("auth.invalidPassword")
        );
        return false;
      }

      if (!this.tempPassword || !this.drupalUser) {
        this.$set(
          this.errorMessage,
          "general",
          this.$t("auth.invalidTempPassword")
        );
        return false;
      }

      return isValid;
    },

    submit() {
      if (!this.validate()) return;

      store
        .dispatch("auth/resetPassword", {
          name: this.drupalUser,
          temporaryPassword: this.tempPassword,
          newPassword: this.password
        })
        .then(() => {
          this.passwordUpdated = true;
          setTimeout(() => {
            this.$router.push({
              name: Routes.Login
            });
          }, 4000);
        })
        .catch(response => {
          if (response?.data?.message) {
            this.$set(this.errorMessage, "general", response.data.message);
            return;
          }
          this.$set(
            this.errorMessage,
            "general",
            this.$t("auth.invalidResetPassword")
          );
        });
    }
  }
});
