import { LearningTopicHttp } from "@/http/client/learning-topic.http";
import { ToolHttp } from "@/http/client/tool.http";
import {
  ToolInterface,
  ToolkitPage,
  ToolkitTeaserInterface,
  ToolListInterface,
  ToolListPage,
  ToolState,
  LearningTopicTeaserInterface
} from "@/interfaces";
import {
  fromLearningTopicToSummary,
  fromToolkitToSummary,
  mapToToolkitTeasers,
  mapFromToolkitToLearningTopicTeasers,
  summaryPage
} from "../utils";

const state: ToolState = {
  toolkits: [],
  tools: [],
  toolListPage: {} as ToolkitPage,
  summaryList: []
};

const actions = {
  fetchToolHubPage: async ({ commit }) => {
    const toolkitPages = await ToolHttp.getToolHub();
    // Injects static summary page
    toolkitPages.push(summaryPage);
    commit("setToolHubPage", toolkitPages);
  },

  fetchToolListPage: async ({ commit, dispatch }, id) => {
    const toolListPage = await ToolHttp.getToolListPage(id);
    const tagsIds = new Set(
      toolListPage.toolListSections.map(({ tagsIds }) => tagsIds).flat()
    );
    dispatch("fetchTools", [...tagsIds]);
    commit("setToolListPage", toolListPage);
  },

  fetchToolkitsForTags: async ({ commit }, tagsIds) => {
    const filter = [
      { path: "field_tags.id", value: [...new Set(tagsIds)], operator: "IN" }
    ];
    const toolkits = await ToolHttp.getToolHub(filter);
    commit("setToolHubPage", toolkits);
  },

  fetchTools: async ({ commit }, tagIds: string[]) => {
    const filters = [];
    if (tagIds.length) {
      filters.push([
        {
          path: "field_tags.id",
          value: tagIds,
          operator: "IN"
        }
      ]);
    }
    const tools = await ToolHttp.getTools(filters);
    commit("setTools", tools);
  },

  fetchToolsForLearningTopic: async ({ commit }, topicId) => {
    const filters = [
      {
        path: "field_learning_topic.id",
        value: topicId
      }
    ];
    const tools = await ToolHttp.getTools(filters);
    commit("setTools", tools);
  },
  fetchSummaryList: async ({ commit }) => {
    // This filter only works because to have a cheat sheet section
    // the editor must also add at least one cheat sheet entry.
    // If that business logic changes to allow empty sections, then
    // this code will need an update
    const filters = [
      {
        path: "field_cheat_sheet_sections",
        operator: "IS NOT NULL"
      }
    ];
    const [learningTopics, toolkits] = await Promise.all([
      LearningTopicHttp.getLearningTopics(filters),
      ToolHttp.getToolListPages(filters)
    ]);
    commit("setSummaryList", [
      ...learningTopics.map(lp => fromLearningTopicToSummary(lp)),
      ...toolkits.map(tk => fromToolkitToSummary(tk))
    ]);
  }
};

const getters = {
  toolkitTeasers: (state): ToolkitTeaserInterface[] => {
    return mapToToolkitTeasers(state.toolkits);
  },

  toolkitsAsLearningTopicTeasers: state => (
    tagsId
  ): LearningTopicTeaserInterface[] => {
    if (!state.toolkits.length) return [];

    const toolkits = mapFromToolkitToLearningTopicTeasers(state.toolkits);
    return toolkits.filter(({ tags }) =>
      tags.some(tag => tagsId.includes(tag.id))
    );
  },

  toolsForTags: state => (tagsId): ToolInterface[] => {
    return state.tools.filter(({ tags }) =>
      tags.some(tag => tagsId.includes(tag.id))
    );
  },

  toolListPage: (state, getters): ToolListPage => {
    if (!state.toolListPage.title) return {} as ToolListPage;
    return {
      ...state.toolListPage,
      toolLists: state.toolListPage.toolListSections.map(
        ({ title, tagsIds }): ToolListInterface => ({
          title,
          items: getters.toolsForTags(tagsIds)
        })
      )
    };
  }
};

const mutations = {
  setToolHubPage(state, toolkits) {
    state.toolkits = toolkits;
  },
  setToolListPage(state, toolListPage) {
    state.toolListPage = toolListPage;
  },
  setTools(state, tools) {
    state.tools = tools;
  },
  setSummaryList(state, list) {
    state.summaryList = list;
  }
};

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
};
