








































import { computed, defineComponent, ref } from "@vue/composition-api";
import SectionHeader from "@/components/FcSectionHeader/FcSectionHeader.vue";
import LearningTopicTeaserList from "@/components/FcLearningTopicTeaserList/FcLearningTopicTeaserList.vue";
import PracticalExamplesTeaserList from "@/components/FcPracticalExamplesTeaserList/FcPracticalExamplesTeaserList.vue";
import ToolList from "@/components/FcToolList/FcToolList.vue";

export default defineComponent({
  components: {
    SectionHeader,
    LearningTopicTeaserList,
    PracticalExamplesTeaserList,
    ToolList
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  watch: {
    id(tagId) {
      this.getPage(tagId);
    }
  },
  setup(props, { root }) {
    const isLoading = ref(true);
    const getPage = async tagId => {
      isLoading.value = true;
      return Promise.all([
        root.$store.dispatch("tag/getLearningTopicsByTag", tagId),
        root.$store.dispatch("tag/getTrainingsByTag", tagId),
        root.$store.dispatch("tag/getToolListsByTag", tagId),
        root.$store.dispatch("training/getVisistedTrainingStatus")
      ]).finally(() => {
        isLoading.value = false;
      });
    };
    getPage(props.id);

    const tag = computed(
      () => root.$store.state.tag.tags.find(tag => tag.id === props.id) || {}
    );

    const learningTopics = computed(() => root.$store.state.tag.learningTopics);
    const trainings = computed(() => root.$store.state.tag.trainings);
    const tools = computed(() => root.$store.state.tag.tools);

    const visitedTrainingIds = computed(
      () => root.$store.state.training.visitedTrainingIds
    );

    return {
      getPage,
      tag,
      learningTopics,
      trainings,
      tools,
      visitedTrainingIds,
      isLoading
    };
  }
});
