import useApi from "@/composition/useApi";
import { ApiEndpoint } from "../endpoint";
import { TagListResponseTranslator } from "../translators/tag";
import { PracticalExamplesHttp } from "./practical-examples";
import { LearningTopicHttp } from "./learning-topic.http";
import { ToolHttp } from "./tool.http";
import { sortByChangedDate } from "../translators/utils";

export class TagHttp {
  static async getAvailableTags() {
    const { client } = useApi();
    const response = await client.get(ApiEndpoint.Tag);
    return TagListResponseTranslator(response);
  }

  static async getLearningTopicsByTag(tagId: string) {
    const learningTopics = await LearningTopicHttp.getLearningTopics(
      [{ path: "field_tags.id", value: tagId }],
      { path: "changed", direction: "DESC" }
    );

    return learningTopics;
  }

  static async getTrainingsByTag(tagId) {
    const trainings = await PracticalExamplesHttp.getPracticalExamples(
      [{ path: "field_tags.id", value: tagId }],
      { path: "changed", direction: "DESC" }
    );

    return trainings.sort(sortByChangedDate);
  }

  static async getToolListsByTag(tagId) {
    const tools = await ToolHttp.getTools(
      [{ path: "field_tags.id", value: tagId }],
      { path: "changed", direction: "DESC" }
    );

    return tools;
  }
}
